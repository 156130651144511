'use strict';
var base = {};
var persistentWishlist = require('core/product/persistentWishlist');
var floatLabel = require('core/floatLabel');
var clientSideValidation = require('core/components/clientSideValidation');

/** Constants */
const $body = $('body');

/**
 * to determine if the product has a size dropdown list
 */
function isSizeDropDown($productContainer){
  var sizeList = $productContainer.find('.js-size-dropdown.show-size-dropdown:visible');
  return sizeList.length > 0;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
  const $modalBody = $('.modal-body');

  $modalBody.spinner().start();

  if ($('#chooseBonusProductModal').length !== 0) {
    $('#chooseBonusProductModal').remove();
  }
  var bonusUrl;
  if (data.bonusChoiceRuleBased) {
    bonusUrl = data.showProductsUrlRuleBased;
  } else {
    bonusUrl = data.showProductsUrlListBased;
  }

  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade bonus-pdt" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
    '<span class="enter-message sr-only" ></span>' +
    '<div class="modal-dialog choose-bonus-product-dialog quick-view-dialog" ' +
    'data-total-qty="' +
    data.maxBonusItems +
    '"' +
    'data-UUID="' +
    data.uuid +
    '"' +
    'data-pliUUID="' +
    data.pliUUID +
    '"' +
    'data-addToCartUrl="' +
    data.addToCartUrl +
    '"' +
    'data-pageStart="0"' +
    'data-pageSize="' +
    data.pageSize +
    '"' +
    'data-moreURL="' +
    data.showProductsUrlRuleBased +
    '"' +
    'data-bonusChoiceRuleBased="' +
    data.bonusChoiceRuleBased +
    '">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '    <span class="gwp_header"> </span>' +
    '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">' +
    '        <span aria-hidden="true" class="previous-icon svg-svg-14-chevron-dims svg-svg-14-chevron"></span>' +
    '    </button>' +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $body.append(htmlString);
  $($modalBody).spinner().start();

  $.ajax({
    url: bonusUrl,
    method: 'GET',
    dataType: 'json',
    success: function (response) {
      var parsedHtml = parseHtml(response.renderedTemplate);
      const $chooseBonusProductModal = $('#chooseBonusProductModal');
      $chooseBonusProductModal.find('.enter-message').text(response.enterDialogMessage);
      $chooseBonusProductModal.find('.modal-header .close .sr-only').text(response.closeButtonText);
      $chooseBonusProductModal.find('.gwp_header').text(response.labels.selectprods);
      $chooseBonusProductModal.find('.modal-body').html(parsedHtml.body);
      $chooseBonusProductModal.find('.modal-footer').html(parsedHtml.footer);
      $chooseBonusProductModal.modal('show');
      $.each(data.selectedBonusProducts, function (selProdIndex, selProd) {
        $('input[name="chooseBonusproductIds"][data-pid="' + selProd.pid + '"]').prop('checked', true);
      });

      $.spinner().stop();
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

/**
 * @param {Object} addToCartConfirmationModal popup after product added to cart
 */
function showAddToCartConfirmation(addToCartConfirmationModal) {
  if (!addToCartConfirmationModal) {
    return;
  }
  $('.product-added-to-cart-modal').remove();
  $('.menu-wrapper, .header').append(addToCartConfirmationModal);

  const $productAddedToCartModal = $('.product-added-to-cart-modal');
  $productAddedToCartModal.show();
  setTimeout(function () {
    $productAddedToCartModal.hide();
  }, 3000);

  if ($('.minicart .popover').is(':visible')) {
    $productAddedToCartModal.filter('.modal').hide();
  }
}

base.showAddToCartConfirmation = showAddToCartConfirmation;

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
base.getQuantitySelected = function ($el) {
  return getQuantitySelector($el).val();
}

base.focusChooseBonusProductModal = function () {
  $body.on('shown.bs.modal', '#chooseBonusProductModal', function () {
      const $chooseBonusProductModal = $('#chooseBonusProductModal');
      $chooseBonusProductModal.siblings().attr('aria-hidden', 'true');
      $chooseBonusProductModal.find('.close').focus();
  });
};

base.onClosingChooseBonusProductModal = function () {
  $body.on('hidden.bs.modal', '#chooseBonusProductModal', function () {
      $('#chooseBonusProductModal').siblings().attr('aria-hidden', 'false');
  });
};

base.trapChooseBonusProductModalFocus = function () {
  $body.on('keydown', '#chooseBonusProductModal', function (e) {
      var focusParams = {
          event: e,
          containerSelector: '#chooseBonusProductModal',
          firstElementSelector: '.close',
          lastElementSelector: '.add-bonus-products'
      };
      focusHelper.setTabNextFocus(focusParams);
  });
};

base.selectBonusProduct = function () {
  $(document).on('click', '.select-bonus-product', function () {
      const $this = $(this);
      var $choiceOfBonusProduct = $this.parents('.choice-of-bonus-product');
      var pid = $this.data('pid');
      var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
      var submittedQty = parseInt($choiceOfBonusProduct.find('.bonus-quantity-select').val(), 10);
      var totalQty = 0;
      $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
          const $this = $(this);
          totalQty += $this.data('qty');
      });
      totalQty += submittedQty;
      var optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
      var valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
      if (totalQty <= maxPids) {
          var selectedBonusProductHtml = ''
          + '<div class="selected-pid row" '
          + 'data-pid="' + pid + '"'
          + 'data-qty="' + submittedQty + '"'
          + 'data-optionID="' + (optionID || '') + '"'
          + 'data-option-selected-value="' + (valueId || '') + '"'
          + '>'
          + '<div class="col-sm-11 col-9 bonus-product-name" >'
          + $choiceOfBonusProduct.find('.product-name').html()
          + '</div>'
          + '<div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>'
          + '</div>'
          ;
          $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
          $('.pre-cart-products').html(totalQty);
          $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
      } else {
          $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
      }
  });
};

base.removeBonusProduct = function () {
  $(document).on('click', '.selected-pid', function () {
      $(this).remove();
      var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
      var count = 0;
      if ($selected.length) {
          $selected.each(function () {
              count += parseInt($(this).data('qty'), 10);
          });
      }

      $('.pre-cart-products').html(count);
      $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
  });
};

base.enableBonusProductSelection = function () {
  $body.on('bonusproduct:updateSelectButton', function (e, response) {
      const $selectBonusProductButton = $('button.select-bonus-product', response.$productContainer);
      $selectBonusProductButton.attr('disabled',
          (!response.product.readyToOrder || !response.product.available));
      var pid = response.product.id;
      $selectBonusProductButton.data('pid', pid);
  });
}

base.showMoreBonusProducts = function () {
  $(document).on('click', '.show-more-bonus-products', function () {
      var url = $(this).data('url');
      const $modalContent = $('.modal-content');
      $('.modal-content').spinner().start();
      $.ajax({
          url: url,
          method: 'GET',
          success: function (html) {
              var parsedHtml = parseHtml(html);
              $('.modal-body').append(parsedHtml.body);
              $('.show-more-bonus-products:first').remove();
              $modalContent.spinner().stop();
          },
          error: function () {
              $modalContent.spinner().stop();
          }
      });
  });
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
base.getPidValue = function ($el) {
  var pid;
  const $element = $($el);
  const $productDetail = $element.closest('.product-detail');
  const $productSet = $('.product-set');
  if ($('#quickViewModal').hasClass('show') && !$productSet.length) {
    pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
  } else if (
    ($('.product-set-detail').length && $productDetail.length && $productDetail.data('pid') !== undefined) ||
    $productSet.length
  ) {
    pid = $productDetail.data('pid');
  } else if ($productSet.length) {
    pid = $productDetail.find('.product-id').text();
  } else if ($element && $element.closest('.product-tile').length) {
    pid = $element.closest('.product').data('pid');
  } else {
    pid = $('.product-detail:not(".bundle-item")').data('pid');
  }

  return pid;
};

base.handleMinicartFeedback = function () {
  $body.on('minicart:feedback', function (e, reqdata) {
    var data = reqdata || e.originalEvent.detail;
    if (!data) {
      return;
    }
    // return and trigger a message product addition exceeded the basket preference limit
    if (data.message && data.message === 'LIMIT_EXCEEDED') {
      const $qvBasketLimitMsg= $productContainer.find('.qv-baskelimit-msg');
      if ($productContainer.is('.product-quickview') && $qvBasketLimitMsg.length > 0) {
        $qvBasketLimitMsg.removeClass('d-none');
      } else {
        $body.trigger('triggerBasketLimitMsgModal');
      }
    } else {
      // In case this is needed it should be added previously on the request
      var autoHideDialog = handlePostCartAdd(data);
      const $pgName = $('.pg-name');
      if (autoHideDialog) {
        $body.trigger('product:afterAddToCart', data);
      }
      // changes made to remove the product from wishlist of the same is being added from WL - Start
      if ($pgName.length > 0 && $pgName.val() === 'wishlist') {
        $.ajax({
          url: wlRemoveUrl,
          type: 'get',
          dataType: 'json',
          data: {},
          success: function () {
            window.location.reload();
          },
          error: function () {
            // do nothing
          }
        });
      }
    }
  })
}

/**
 * clear CTA button when the selected variant it's not a sold out
 */
function resetATCButton($productContainer, msgs) {
  var isEditModal = $productContainer.closest('#editProductModal');
  var dialog = $productContainer.closest('.quick-view-dialog');
  var msg = msgs.addtobag;
  var $addToBagButton;

  if (dialog && dialog.length > 0 && isEditModal.length < 1) {
    $addToBagButton = $productContainer.find('button.add-to-cart-global');
  } else if (isEditModal && isEditModal.length > 0) {
    $addToBagButton = $productContainer.find('.update-cart-product-global');
    msg = msgs.update;
    if ($addToBagButton.length < 1) {
      $addToBagButton = $productContainer.find('button.add-to-cart-global');
      msg = msgs.addtobag;
    }
  } else {
    $addToBagButton = $productContainer.find('button.add-to-cart');
  }
  $addToBagButton.removeClass('soldout').removeClass('disabled');
  $addToBagButton.text(msg);
  $addToBagButton.removeAttr('disabled');
}

function resetSizeListOption($option, msgs) {
  if ($option[0].text.includes(msgs.soldout)) {
    $option[0].text = $option[0].text.replace(msgs.soldout, '');
  }
  if ($option[0].text.includes(msgs.addtowaitlist)) {
    $option[0].text = $option[0].text.replace(msgs.addtowaitlist, '');
  }
}
/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
  if (!attributes) {
    return '';
  }

  var html = '';

  attributes.forEach(function (attributeGroup) {
    if (attributeGroup.ID === 'mainAttributes') {
      attributeGroup.attributes.forEach(function (attribute) {
        html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
      });
    }
  });

  return html;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
  return $('.add-to-cart-url').val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
  var attrSelected = false;
  attr.values.forEach(function (attrValue) {
    var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
    $attrValue.attr('value', attrValue.url);
    var $swatchButton = $attrValue.parent();

    if (attrValue.selected) {
      $attrValue.addClass('selected');
      $swatchButton.addClass('selected');
      $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
      attrSelected = true;
      $productContainer.find('.attribute .' + attr.id + ' .attr-name').html(attr.attrDisplay);

      // Color dropdown update on change of color swatch
      if ($($attrValue).closest('.color-wrapper').length > 0 && $('.custom-color-dropdown').length > 0 && $('.select-color').length > 0) {
        $('.select-color option').each(function (i) {
          if ($(this).text().trim() === $($attrValue).html().trim()) {
            $(this).closest('.select-color').prop('selectedIndex', i);
            window.formFields.updateSelect();
          }
        });
      }
    } else {
      $attrValue.removeClass('selected');
      $swatchButton.removeClass('selected');
      $attrValue.siblings('.selected-assistive-text').empty();
      $productContainer.find('.attribute .' + attr.id + ' .attr-name').html(attr.attrDisplay);
    }

    if ($productContainer.find('.js-saks-newdesign').length > 0) {
      const $attributeName = $productContainer.find('.attribute .' + attr.id + ' .attr-name');
      $attributeName.find('.attribute-displayName').html(attr.displayName + ' ');
      $attributeName.find('.attribute-displayValue').html(attr.displayValue);
    } else {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .html($('.edit_quickview_container').length > 0 ? attr.attrEditDisplay : attr.attrDisplay);
    }
    if (attrValue.url) {
      $swatchButton.attr('data-url', attrValue.url);
    } else {
      $swatchButton.removeAttr('data-url');
    }

    // Disable if not selectable
    $attrValue.removeClass('selectable unselectable');

    $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
  });
  if (!attrSelected) {
    $productContainer.find('select.select-color').find('option:first').prop('selected', true).change();
  }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
  var $attr = '[data-attr="' + attr.id + '"]';
  var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
  $defaultOption.attr('value', attr.resetUrl);

  $productContainer.find($('ul.' + attr.id + '-attribute').find('li[data-attr-value]')).removeAttr('selected');
  $productContainer.find('.attribute .' + attr.id + ' .attr-name .text2').remove();

  var sizeSelected = false;
  window.isSoldoutSelected = false;
  var productHasSizeList = isSizeDropDown($productContainer);

  attr.values.forEach(function (attrValue) {
    var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
    if ($attrValue.length == 2) {
      // update 'option' values along with 'li'
      var $element = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]' + 'option');
      if ($element.length > 0) {
        $attrValue.val(attrValue.url).removeAttr('disabled');
        $attrValue.attr('disabled', false);
        resetSizeListOption($element, msgs);

        if (!attrValue.selectable) {
          if (!productHasSizeList || (productHasSizeList && !attrValue.waitlist) && (productHasSizeList && attrValue.variantAvailabilityStatus !== 'NOT_AVAILABLE')) {
            $attrValue.attr('disabled', true);
          }

          if(productHasSizeList) {
            var sizeVal = $attrValue.data('attr-value');
            if (attrValue.waitlist) {
              $attrValue.text(sizeVal + ' ' + msgs.addtowaitlist);
            } else if(attrValue.variantAvailabilityStatus === 'NOT_AVAILABLE') {
              $attrValue.text(sizeVal + ' ' + msgs.soldout);
            }
          }
        }

        if (attrValue.selected) {
          if(productHasSizeList && !attrValue.waitlist && attrValue.variantAvailabilityStatus === 'NOT_AVAILABLE') {
            window.isSoldoutSelected = true;
          }
          $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]' + 'option').attr('selected', 'selected');
          $productContainer.find('.attribute .' + attr.id + ' .attr-name').html(attr.attrDisplay);
          sizeSelected = true;
          if (attrValue.evaluationsColorsForSelectedSize != undefined && attrValue.evaluationsColorsForSelectedSize.length > 0) {
            attrValue.evaluationsColorsForSelectedSize.forEach(function (colorEvaluation) {
              var selector = ".color-value.swatch-circle.swatch-value[data-attr-value='$colorReplace']";
              selector = selector.replace('$colorReplace', colorEvaluation.colorValue);
              var $color = $attrValue.closest('.attributes').find(selector);
              if (!colorEvaluation.selectable) {
                $color.removeClass('selectable').addClass('unselectable');
              } else {
                $color.removeClass('unselectable').addClass('selectable');
              }
            });
          }
        }
      }
    }
    $attrValue.find('a').attr('data-href', attrValue.url).removeAttr('disabled');
    $attrValue.attr('disabled', false);
    if ((!productHasSizeList && !attrValue.selectable) || (productHasSizeList && !attrValue.selectable && !attrValue.waitlist) && (productHasSizeList && !attrValue.selectable && attrValue.variantAvailabilityStatus !== 'NOT_AVAILABLE')) {
      $attrValue.attr('disabled', true);
    }

    if (attrValue.selected) {
      if(productHasSizeList && !attrValue.waitlist && attrValue.variantAvailabilityStatus === 'NOT_AVAILABLE') {
        window.isSoldoutSelected = true;
      }
      $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]').attr('selected', 'selected');
      $productContainer.find('.attribute .' + attr.id + ' .attr-name').html(attr.attrDisplay);
      if (attrValue.evaluationsColorsForSelectedSize != undefined && attrValue.evaluationsColorsForSelectedSize.length > 0) {
        attrValue.evaluationsColorsForSelectedSize.forEach(function (colorEvaluation) {
          var selector = ".color-value.swatch-circle.swatch-value[data-attr-value='$colorReplace']";
          selector = selector.replace('$colorReplace', colorEvaluation.colorValue);
          var $color = $attrValue.closest('.attributes').find(selector);
          if (!colorEvaluation.selectable) {
            $color.removeClass('selectable').addClass('unselectable');
          } else {
            $color.removeClass('unselectable').addClass('selectable');
          }
        });
      }
    }

    if ($productContainer.find('.js-saks-newdesign').length > 0) {
      const $attributeName = $productContainer.find('.attribute .' + attr.id + ' .attr-name');
      $attributeName.find('.attribute-displayName').html(attr.displayName + ' ');
      $attributeName.find('.attribute-displayValue').html(attr.displayValue);
    } else {
      $productContainer
        .find('.attribute .' + attr.id + ' .attr-name')
        .html($('.edit_quickview_container').length > 0 ? attr.attrEditDisplay : attr.attrDisplay);
    }

    $productContainer
      .find($attr + ' [data-attr-value="' + attrValue.value + '"]')
      .attr('data-selectableattribute', attrValue.selectable || (!attrValue.selectable && attrValue.waitlist) || (productHasSizeList && !attrValue.selectable && attrValue.variantAvailabilityStatus === 'NOT_AVAILABLE'));
  });
  if (!sizeSelected) {
    $productContainer.find('.js-size-dropdown').prop('selectedIndex', 0);
  }
  if(!window.isSoldoutSelected && productHasSizeList) {
    resetATCButton($productContainer, msgs);
  }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
  // Currently, the only attribute type that has image swatches is Color.
  var attrsWithSwatches = ['color', 'Design'];

  attrs.forEach(function (attr) {
    if (attrsWithSwatches.indexOf(attr.id) > -1) {
      processSwatchValues(attr, $productContainer, msgs);
    } else {
      processNonSwatchValues(attr, $productContainer, msgs);
    }
  });
}

function updateAttrsForMaster(attrs, $productContainer) {
  try {
    var productHasSizeList = isSizeDropDown($productContainer);
    attrs.forEach(function (attr) {
      attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        $attrValue.attr('disabled', false);
        if ((!productHasSizeList && !attrValue.selectable) || (productHasSizeList && !attrValue.selectable && !attrValue.waitlist) && (productHasSizeList && !attrValue.selectable && attrValue.variantAvailabilityStatus !== 'NOT_AVAILABLE')) {
          $attrValue.attr('disabled', true);
        }
        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');
        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
      });
    });
  } catch (e) {}
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
  return $el && $('.set-items').length ? $($el).closest('.product-detail').find('.quantity-select') : $('.quantity-select');
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
  var $html = $('<div>').append($.parseHTML(html));

  var body = $html.find('.choice-of-bonus-product');
  var footer = $html.find('.modal-footer').children();

  return {
    body: body,
    footer: footer
  };
}


/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
  var childProducts = [];
  $('.bundle-item').each(function () {
    const $this = $(this);

    childProducts.push({
      pid: $this.find('.product-id').text(),
      quantity: parseInt($this.find('label.quantity').data('quantity'), 10)
    });
  });

  return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
  var options = $productContainer
    .find('.product-option')
    .map(function () {
      const $this = $(this);
      var $elOption = $this.find('.options-select');
      var urlValue = $elOption.val();
      var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
      return {
        optionId: $this.data('option-id'),
        selectedValueId: selectedValueId
      };
    })
    .toArray();

  return JSON.stringify(options);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
  var autoHideDialog = true;
  $('.minicart').trigger('count:update', response);
  var messageType = response.error ? 'alert-danger' : 'alert-success';
  if (!response.error && response.addToCartConfirmationModal) {
    const $atcAddedToBag = $body.find('.ATC-addedToBag');
    const $pgName = $('.pg-name');

    $atcAddedToBag.attr('temp-btn-data', $atcAddedToBag.text());
    $atcAddedToBag.attr('disabled','disabled');
    $atcAddedToBag.addClass('disabled').text('ADDED TO BAG');
    if ($pgName.length > 0 && $pgName.val() === 'wishlist') {
      $body.trigger('adobeTagManager:moveFavToBag', response);
    } else {
      $body.trigger('adobeTagManager:addToBag', response);
    }
    window.base.showAddToCartConfirmation(response.addToCartConfirmationModal);
    return autoHideDialog;
  }
  // don't show the error message in PLP (Product array)-SFDEV-7748
  if (response.sourcepage && (response.sourcepage == 'pdp' || response.sourcepage == 'qv')){
    let $addToCartMessages = $('.add-to-cart-messages');
    if ($addToCartMessages.length === 0) {
      $('.attribute.quantity').append('<div class="add-to-cart-messages"></div>');
      $addToCartMessages = $('.add-to-cart-messages');
    }
    if (response.message != '') {
      $addToCartMessages.html(
        $('<div class="error" role="alert"></div>').text(response.message)
      );
      autoHideDialog = false;
    }

    setTimeout(function () {
      $addToCartMessages.find('.error').remove();
    }, 3000);
  }
  return autoHideDialog;
}

/**
 * PDP Swipe detect function.
 *
 * @param {el} el - Element
 * @param {callback} callback - Callback
 **/
function swipeDetect(el, callback) {
  var touchsurface = el;
  var swipedir;
  var startX;
  var startY;
  var distX;
  var distY;
  var threshold = 50;
  var restraint = 100;
  var allowedTime = 300;
  var elapsedTime;
  var startTime;
  var handleswipe = callback || function () {};

  /**
   * PDP Event Down
   *
   * @param {event} event - event
   **/
  function eventDown(event) {
    swipedir = 'none';
    startTime = new Date().getTime();
    event.preventDefault();
  }

  /**
   * PDP Event Up
   *
   * @param {distXLoc} distXLoc - distX
   * @param {distYLoc} distYLoc - distY
   * @param {event} event - event
   **/
  function eventUp(distXLoc, distYLoc, event) {
    elapsedTime = new Date().getTime() - startTime;
    if (elapsedTime <= allowedTime) {
      if (Math.abs(distXLoc) >= threshold && Math.abs(distYLoc) <= restraint) {
        swipedir = distXLoc < 0 ? 'left' : 'right';
      } else if (Math.abs(distYLoc) >= threshold && Math.abs(distXLoc) <= restraint) {
        swipedir = distYLoc < 0 ? 'up' : 'down';
      }
    }
    handleswipe(swipedir);
    event.preventDefault();
  }

  touchsurface.addEventListener(
    'mousedown',
    function (e) {
      startX = e.pageX;
      startY = e.pageY;
      eventDown(e);
    },
    false
  );

  touchsurface.addEventListener(
    'mousemove',
    function (e) {
      e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    'mouseup',
    function (e) {
      distX = e.pageX - startX;
      distY = e.pageY - startY;
      eventUp(distX, distY, e);
    },
    false
  );

  touchsurface.addEventListener(
    'touchstart',
    function (e) {
      var touchobj = e.changedTouches[0];
      startX = touchobj.pageX;
      startY = touchobj.pageY;
      eventDown(e);
    },
    false
  );

  touchsurface.addEventListener(
    'touchmove',
    function (e) {
      e.preventDefault();
    },
    false
  );

  touchsurface.addEventListener(
    'touchend',
    function (e) {
      var touchobj = e.changedTouches[0];
      distX = touchobj.pageX - startX;
      distY = touchobj.pageY - startY;
      eventUp(distX, distY, e);
    },
    false
  );
}

/* Color swatch Slick needs no get Reinitialized on window Resize after it was destroyed */
$(window).on('load resize orientationchange', function (event) {
  $('.color-wrapper').each(function () {
    var $colorwrapper = $(this);
    /* Initializes a slick carousel only on mobile screens */
    /* slick on mobile */
    if ($(window).width() > 767) {
      if ($colorwrapper.hasClass('slick-initialized')) {
        $colorwrapper.slick('unslick');
      }
    } else {
      if (!$colorwrapper.hasClass('slick-initialized')) {
        $colorwrapper.slick({
          dots: false,
          infinite: false,
          slidesToShow: 7.5,
          slidesToScroll: 7.5,
          mobileFirst: true,
          arrows: false,
          autoplay: false
        });
        if($body.hasClass('product-details-page')) {
          var maxwidth = Math.floor(( window.innerWidth - 20) / 7.5);
          $(this).find('.slick-slide').css('max-width', maxwidth);
        }
      }
    }
  });
  if ($(window).width() < 767) {
    var imgMinHeight = $('.primary-images-wrapper').height();
    $('.primary-images-wrapper').css('min-height', imgMinHeight) ;
  }
});

/**
 * PDP play select video player
 *
 * @param {pid} pid - Product id
 **/
var playVideoPlayer = function (pid) {
  $('.s7videoplayer .s7iconeffect').trigger('click');
};

base.playVideoPlayer = playVideoPlayer;

function findVideoAsset(image) {
  var asset;
  var imgAsset = image[image.length - 1];
  if (imgAsset && imgAsset.length > 0) {
    var videoPathAsset;
    var videoPath = imgAsset.split('/');
    if (videoPath[1].length > 0) {
      videoPathAsset = videoPath[1].split('?');
      if (videoPathAsset[0].length > 0) {
        asset = videoPath[0] + '/' + videoPathAsset[0];
      } else {
        asset = videoPath[0] + '/' + videoPath[1];
      }
    } else {
      asset = videoPath[0] + '/' + videoPath[1];
    }
  }

  return asset;
}

/**
 * PDP activate video player
 *
 **/
var activateVideoPlayer = function () {
  if ($('.enablePDPVideoPlayer').length > 0) {
    $('.s7-viewer-video').empty();
    const $productDetail = $('.product-detail');
    const $pdpCarousel = $productDetail.find('div.pdp-carousel');
    const $videoPlayer = $productDetail.find('.thumb-nail.video-player');
    var serverUrl = $pdpCarousel.data('scene7hosturl');
    var videoserverUrl = $pdpCarousel.data('scene7videohosturl');
    var asset;
    var image;
    var params = {};
    var videoAsset;
    if ($videoPlayer.length > 0) {
      image = $videoPlayer.find('img').attr('src').split('image/'); // eslint-disable-line
      var videoAsset = findVideoAsset(image);
      params.asset = videoAsset;
      params.serverurl = serverUrl;
      params.videoserverUrl = videoserverUrl;

      console.log('s7 viewer params from saks: ', params, $(this).attr('id'));
      var isInProductSet = $productDetail.hasClass('set-item');

      $('.s7-viewer-video').each(function () {
        const $this = $(this);

        if (isInProductSet) {
          image = $this.closest('.product-detail').find('.thumb-nail.video-player').find('img').attr('src').split('image/'); // eslint-disable-line
          asset = findVideoAsset(image);
          if (asset) {
            params.asset = asset;
          }
        }
        if ($this.attr('id') && typeof s7viewers !== 'undefined') {
          new s7viewers.VideoViewer({
            // eslint-disable-line
            containerId: $this.attr('id'), // eslint-disable-line
            handlers: {
              trackEvent: function (objID, compClass, instName, timeStamp, eventInfo) {
                const eventType = eventInfo.split(',')[0];
                const $primaryImage = $('.primary-images');
                const ismobile = window.innerWidth < 768;

                if (ismobile) {
                  if (eventType === "FULLSCREEN") {
                      $primaryImage.addClass('m-video m-close_animation');
                      $primaryImage.slick('unslick');
                  } else if (eventType === "UN_FULLSCREEN") {
                      const childrenLength = $primaryImage.children().length;
                      const slickSliderSpeed = 150; // ms

                      $primaryImage.removeClass('m-video');
                      $primaryImage.addClass('m-scroll_to');
                      activatePrimarySlick($primaryImage);
                      $primaryImage.slick('slickGoTo', childrenLength - 1, false);
                      setTimeout(() => {
                        $primaryImage.removeClass('m-scroll_to');
                      }, slickSliderSpeed);
                  }
                }
              }
            },
            params: params // eslint-disable-line
          }).init(); // eslint-disable-line
        }
      });
    }
  }
};

base.activateVideoPlayer = activateVideoPlayer;

/**
 * PDP primary function slider functionality
 *
 * @param {jquery} slider - slider jquery element
 **/
var activatePrimarySlick = function (slider) {
  var primaryImg = '.primary-images-container .primary-images';
  if ($('.product-detail').hasClass('product-set-detail')) {
    window.hbcSlider.hbcSliderInit('primary-images-set', slider);
  } else if ($('.product-detail').hasClass('product-quickview')) {
    window.hbcSlider.hbcSliderInit('quick-view-images');
  } else {
    window.hbcSlider.hbcSliderInit('primary-images');
  }
  $(primaryImg).on('afterChange', function () {
    const $this = $(this);

    $this.find('.primary-image').removeClass('active');
    $this.find('.slick-active .primary-image').addClass('active');

    if ($this.find('.slick-current').find('.video-place-holder').is('.video-place-holder')) {
      $this.next('.s7-viewer-video').addClass('active');
    } else {
      $this.next('.s7-viewer-video').removeClass('active');
    }

    if (
      $this.parents('.primary-images-wrapper').find('.s7playpausebutton[aria-label="Pause"]').length > 0 &&
      (window.innerWidth < 1024 || $('.product-detail').hasClass('product-set-detail') || $('.product-detail').hasClass('product-quickview'))
    ) {
      $this.parents('.primary-images-wrapper').find('.s7playpausebutton[aria-label="Pause"]').trigger('click');
    }
  });
};

base.activatePrimarySlick = activatePrimarySlick;

/**
 * PDP create carousel functionality
 *
 * @param {imgs} imgs - Object of image details
 * @param {containerDetails} $productContainer - Container Details
 **/
var createCarousel = function (imgs, $productContainer, video) {
  var $carousel = $productContainer.find('.pdp-carousel');
  var isQuickView = $productContainer.is('.product-quickview');
  var isEditProductModal = $productContainer.is('.m-edit_product');
  var $primaryThumb = $carousel.find('.primary-thumbnails');
  var $primaryImage = $carousel.find('.primary-images');
  var $zoomWindowThumb = $productContainer.find('.zoom-thumbnails');
  var primaryThumbData = '';
  var primaryImageData = '';
  var zoomWindowThumbData = '';
  var s7ProductID = '';
  if ($('#s7Modal').length > 0) {
    s7ProductID = $('#s7Modal').data('productid');
  }

  for (var i = 0; i < imgs.length; i++) {
    var thumbnailDisplay  = imgs.length === 1 ? 'd-none' : 'd-lg-block';
    primaryThumbData =
      primaryThumbData +
      '<li class="primary-thumbnail"><a href="#primary-image-' +
      i +
      '" class="thumb-nail ' + thumbnailDisplay + '"><img loading="lazy" src="' +
      imgs[i].url +
      '" class="d-block img-fluid" alt="' +
      imgs[i].alt +
      ' image number ' +
      parseInt(imgs[i].index, 10) +
      '" title="' +
      imgs[i].title +
      '" itemprop="image" /></a></li>';
    if (isQuickView) {
      const imgTagAttributes = isEditProductModal ? '' : ' loading="lazy"';
      primaryImageData += `
        <div id="primary-image-${i}" data-index="${i}" class="primary-image">
          <img ${imgTagAttributes} src="${imgs[i].url}" class="d-block img-fluid primary-image-img"
            alt="${imgs[i].alt} image number ${parseInt(imgs[i].index, 10)}"
            title="${imgs[i].title}"
            itemprop="image" />
          <div id="s7viewer-qv-${i}" class="s7-viewer-qv"></div>
        </div>`;
    } else {
      primaryImageData =
        primaryImageData +
        '<div id="primary-image-' +
        i +
        '" data-index="' +
        i +
        '" class="primary-image"><img loading="lazy" src="' +
        imgs[i].url +
        '" class="d-block img-fluid primary-image-img" alt="' +
        imgs[i].alt +
        ' image number ' +
        parseInt(imgs[i].index, 10) +
        '" title="' +
        imgs[i].title +
        '" itemprop="image" /></div>';
    }
    zoomWindowThumbData =
      zoomWindowThumbData +
      '<div class="thumb-nail"><img loading="lazy" src="' +
      imgs[i].url +
      '" class="d-block img-fluid" alt="' +
      imgs[i].alt +
      ' image number ' +
      parseInt(imgs[i].index, 10) +
      '" title="' +
      imgs[i].title +
      '" itemprop="image" /></div>';
  }
  if (video) {
    for (var i = 0; i < video.length; i++) {
      if (video[i].url.indexOf('noimage') == -1) {
        primaryThumbData =
          primaryThumbData +
          '<li class="primary-thumbnail"><a href="#s7viewer-video-' +
          s7ProductID +
          '" class="thumb-nail d-lg-block video-player"><img loading="lazy" src="' +
          video[i].url +
          '" class="d-block img-fluid" alt="' +
          video[i].alt +
          ' image number ' +
          parseInt(video[i].index, 10) +
          '" title="' +
          video[i].title +
          '" itemprop="image" /><span class="video-player-bg"></span></a>';
        primaryImageData = primaryImageData + '<div id="s7viewer-video-' + s7ProductID + '" class="s7-viewer-video"></div>';
      }
    }
  }

  if ($primaryThumb.hasClass('slick-initialized')) {
    $primaryThumb.slick('unslick');
  }
  $primaryThumb.empty().append(primaryThumbData).find('.thumb-nail').first().addClass('active');
  if (imgs && imgs.length == 1) {
    $primaryThumb.find('.thumb-nail').first().addClass('d-none');
  }

  if ($primaryImage.hasClass('slick-initialized')) {
    $primaryImage.slick('unslick');
    $('.s7-viewer-video').empty();
  }
  $primaryImage.empty().removeClass('touchscreen-slider-initialized').append(primaryImageData).find('.primary-image').first().addClass('active');
  $('.product-detail .primary-images-container .primary-images-wrapper .touchscreen-slider-dots.slick-dots').remove();

  activatePrimarySlick($primaryImage);

  if ($zoomWindowThumb.hasClass('slick-initialized')) {
    $zoomWindowThumb.slick('unslick');
  }
  $zoomWindowThumb.empty().append(zoomWindowThumbData).find('.thumb-nail').first().addClass('active');
};

base.createCarousel = createCarousel;

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer, callback) {
  var availabilityValue = '';
  var availabilityMessages = response.product.availability.messages;
  var availabilityUrl = response.availabilityUrl;
  if (!response.product.readyToOrder) {
    availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
  } else {
    var qtyValue = $('.quantity-val', $productContainer).data('oldValue');
    if (isNaN(qtyValue)) {
      qtyValue = 1;
    }
    availabilityMessages.forEach(function (message) {
      if (response.product.available && response.product.availability.isAboveThresholdLevel) {
        var colorStyle = response.product.availability.hexColorCode;
        if (colorStyle) {
          colorStyle = 'style=color:' + colorStyle;
        }
        availabilityValue += '<li><div ' + colorStyle + '>' + message + '</div></li>';
      }
      if (!response.product.available || !response.product.availability.isAboveThresholdLevel) {
        if (message != '') {
          const $addToCartMessages = $('.add-to-cart-messages');
          if ($addToCartMessages.length === 0) {
            $('.attribute.quantity').append('<div class="add-to-cart-messages"></div>');
            $addToCartMessages.html('<div class="error" role="alert">' + message + '</div>');
            setTimeout(function () {
              $('.add-to-cart-messages .error').remove();
            }, 3000);
          } else {
            $addToCartMessages.html('<div class="error" role="alert">' + message + '</div>');
            setTimeout(function () {
              $addToCartMessages.find('.error').remove();
            }, 3000);
          }
        }
      }
    });

    const $purchaseLimit = $productContainer.find('.product-availability .purchaselimit');
    const $addToCart = $('button.add-to-cart', $productContainer);
    const $addToCartGlobal = $('button.add-to-cart-global', $productContainer);

    $addToCart.add($addToCartGlobal).data('producttype', response.product.productType);
    $purchaseLimit.empty();

    if (response.product.orderableNotInPurchaselimit && response.product.readyToOrder && !response.product.availability.isInPurchaselimit) {
      $purchaseLimit.html(response.product.availability.isInPurchaselimitMessage);
      $purchaseLimit.attr('aria-describedby', 'purchase-limit');
      var formData = {};
      formData.errorFields = [response.product.availability.isInPurchaselimitMessage];
      formData.formName = 'Add to Cart';
      $body.trigger('adobeTagManager:formError', formData);
      if (response.product.purchaselimit) {
        qtyValue = response.product.purchaselimit;
      }
      setTimeout(function(){$('.quantity-val', $productContainer).val(qtyValue); }, 3000);
    } else {
      if (!response.product.available) {
        if (response.product.availability.ats > 0) {
          setTimeout(function(){
            $('.quantity-val', $productContainer).val(response.product.availability.ats);
          }, 3000);
          updateQuantities(response.product.quantities, $productContainer);
          $productContainer.find('.quantity-select').val(response.product.availability.ats).trigger('change');
          response.product.available = true;
          response.product.limitedVarinatQtyUpdated = true;
          $addToCart.add($addToCartGlobal).text(response.resources.addtobag);
        }
        $productContainer.find('.product-availability .purchaselimit').html(response.product.availability.outofstockmessage);
      }
      $addToCart.add($addToCartGlobal).attr('disabled', !response.product.available);
    }
  }
  $($productContainer).trigger('product:updateAvailability', {
    product: response.product,
    $productContainer: $productContainer,
    message: availabilityValue,
    resources: response.resources
  });
  callback();
}

/**
 * Get Error fields
 */
function getErrorFields() {
  if (!$('.adobelaunch__colorlink.selected').length && $('.adobelaunch__colorlink').length != 1) {
    // return $('.adobelaunch__colorlink').closest('div[data-attr]').data('attr');
    return $('.adobelaunch__colorlink').closest('div[data-attr]').data('attr');
  }
  if (!$('.adobelaunch__sizelink.selected').length && $('.adobelaunch__sizelink').length != 1) {
    // return $('.adobelaunch__sizelink').closest('div[data-attr]').data('attr');
    return $('.adobelaunch__sizelink').closest('div[data-attr]').data('attr');
  }
  return '';
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, callback) {
  var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
  var isVaraint;
  if ($productContainer.length && response.product.variationAttributes) {
    updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
    isVaraint = response.product.productType === 'variant';
    if (isChoiceOfBonusProducts && isVaraint) {
      const $bonusProductItem = $productContainer.parent('.bonus-product-item');

      $bonusProductItem.data('pid', response.product.id);
      $bonusProductItem.data('ready-to-order', response.product.readyToOrder);
    }
  }

  var dialog = $($productContainer).closest('.quick-view-dialog');
  // update to add to cart buttons
  var $buttonAddToCart = $('button.add-to-cart');
  if ($productContainer.hasClass('set-item')) {
    $buttonAddToCart = $productContainer.find('button.add-to-cart');
  }
  $buttonAddToCart.data('readytoorder', response.product.readyToOrder && response.product.available);
  $buttonAddToCart.data('readytoordertext', response.product.readyToOrderMsg);

  if (window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments()) {

    var $applepayDiv = $('.pdp-apple-pay-button');
    var $applepayButton = $('.dw-apple-pay-button');

    if ($applepayButton.length) {
      $applepayButton.attr('sku', response.product.id);
    }

    if ($applepayDiv.length) {
      $applepayDiv.data('readytoorder', response.product.readyToOrder && response.product.available);
      $applepayDiv.data('readytoordertext', response.product.readyToOrderMsg);
    }
  }

  var sDiv = $('<div class="col-12 select-size-div">');
  sDiv.attr('aria-describedby', getErrorFields());
  sDiv.html(response.product.readyToOrderMsg);

  if (dialog && dialog.length > 0) {
    const $addToCartGlobalDialog = $('button.add-to-cart-global', dialog);
    const $updateCartGlobalDialog = $('button.update-cart-product-global', dialog);

    $addToCartGlobalDialog.attr('data-addwl-pid', response.product.id);
    $addToCartGlobalDialog.attr('data-readytoorder', response.product.readyToOrder && response.product.available);
    $addToCartGlobalDialog.attr('data-readytoordertext', response.product.readyToOrderMsg);
    $updateCartGlobalDialog.data('readytoorder', response.product.readyToOrder && response.product.available);
    $updateCartGlobalDialog.data('readytoordertext', response.product.readyToOrderMsg);
    $('.select-size-color', dialog).html(sDiv);

    // update qty selector
    const $qtyBagSection = $productContainer.find('.qty-bag-section');
    $qtyBagSection.toggleClass('d-none', response.product.disableQtySelector);
    $qtyBagSection.find('.content').toggleClass('d-none', response.product.disableQtySelector);
  }

  const $quantity = $productContainer.find('.quantity');

  $quantity.toggleClass('d-none', response.product.disableQtySelector);
  $quantity.find('.content').toggleClass('d-none', response.product.disableQtySelector);

  // Update primary images
  var primaryImageUrls = response.product.images.large;
  var videoUrls = response.product.images.video;
  if (videoUrls && window.s7viewers) {
    createCarousel(primaryImageUrls, $productContainer, videoUrls);
    activateVideoPlayer();
  } else {
    createCarousel(primaryImageUrls, $productContainer);
  }

  // Update pricing
  if (!isChoiceOfBonusProducts) {
    var $priceSelector = $('.prices .price', $productContainer).length ? $('.prices .price', $productContainer) : $('.prices .price');
    $priceSelector.html(response.product.price.html);
  }

  // Update promotions
  if (
    response.product.promotionalPricing &&
    response.product.promotionalPricing.isPromotionalPrice &&
    response.product.promotionalPricing.promoMessage !== ''
  ) {
    $('.promotion-pricing', $productContainer).empty().html(response.product.promotionalPricing.priceHtml);
    $('.promotion-pricing', $productContainer).removeClass('d-none');
    $('.promotions', $productContainer).addClass('d-none');
  } else {
    $('.promotion-pricing', $productContainer).addClass('d-none');
    $('.promotions', $productContainer).removeClass('d-none');
    $('.promotions', $productContainer).empty().html(response.product.promotionsHtml);
  }

  updateAvailability(response, $productContainer, callback);
  persistentWishlist.makrSingleProductWishlisted(response.product.id, $productContainer);

  var isPDP = $body.is('.product-details-page');
  if(isPDP && window.innerWidth >= 1200) {
    var imageThumbnails = $('.primary-thumbnails');
    var imageThumbnailsLength = imageThumbnails.length > 0 ? imageThumbnails.children().length : 0;
    var isScrollable = imageThumbnails.is('.scrollable');
    if(window.detailThumbnailImageCarousel && ((imageThumbnailsLength > 5 && !isScrollable) || (imageThumbnailsLength <= 5 && isScrollable) )) {
      window.detailThumbnailImageCarousel();
    }
  }
  if (isChoiceOfBonusProducts) {
    var $selectButton = $productContainer.find('.select-bonus-product');
    $selectButton.trigger('bonusproduct:updateSelectButton', {
      product: response.product,
      $productContainer: $productContainer
    });
  } else {
    // Enable "Add to Cart" button if all required attributes have been selected
    // eslint-disable-next-line no-lonely-if
    if ($('.page').data('producttype') === 'set') {
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global', $productContainer)
        .trigger('product:updateAddToCart', {
          product: response.product,
          $productContainer: $productContainer
        })
        .trigger('product:statusUpdate', response.product);
    } else {
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').each(function (e) {
        const $this = $(this);

        if (!$this.closest('.recommendations').length) {
          $this.trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer
          })
          .trigger('product:statusUpdate', response.product);
        }
      })
    }
  }

  if (response.product.readyToOrderMsg === '') {
    $('.select-size-color').empty();
  }

  // Update attributes
  $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
  if (window.isSoldoutSelected && isSizeDropDown($productContainer)) {
    var isEditModal = $productContainer.closest('#editProductModal');
    var addToBagButton;
    if (dialog && dialog.length > 0 && isEditModal.length < 1) {
      addToBagButton = $productContainer.find('button.add-to-cart-global');
    } else if (isEditModal && isEditModal.length > 0) {
      addToBagButton = $productContainer.find('.update-cart-product-global');
      if (addToBagButton.length < 1) {
        addToBagButton = $productContainer.find('button.add-to-cart-global');
      }
    } else {
      addToBagButton = $productContainer.find('button.add-to-cart');
    }
    addToBagButton.addClass('soldout').addClass('disabled');
    addToBagButton.text(response.resources.soldout);
    addToBagButton.attr('disabled', 'disabled');
  }
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
  options.forEach(function (option) {
    var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id + '"]');
    option.values.forEach(function (value) {
      var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
      valueEl.val(value.url);
    });
  });
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
  if (!($productContainer.parent('.bonus-product-item').length > 0)) {
    var optionsHtml = quantities
      .map(function (quantity) {
        var selected = quantity.selected ? ' selected ' : '';
        return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' + selected + '>' + quantity.value + '</option>';
      })
      .join('');
    getQuantitySelector($productContainer).empty().html(optionsHtml);
  }
}

/**
 * Updates the Marketplace DOM elements post Ajax call
 * @param {Product} product -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateMarketPlace(product, $productContainer) {
  if (!product.isMarketPlaceProduct) {
    $productContainer.find('.marketplace-container').addClass('d-none');
  } else {
    $productContainer.find('.marketplace-container').removeClass('d-none');
  }
}

/**
 * Show basket limit messaging mmodal
 */
$body.on('triggerBasketLimitMsgModal', function () {
  if ($('#pliLimitMessagingModal').length > 0) {
    $('#pliLimitMessagingModal').modal('show');
  }
});


base.preOrderBadge = function (product, productContainer) {
  if (product.preOrder && product.preOrder.applicable && product.preOrder.applicable === true) {
    productContainer.find('.edit-cart-preorder-badge').empty().text(product.preOrder.preorderButtonName);
    productContainer.find('.klarna-container').addClass('d-none');
  } else {
    productContainer.find('.edit-cart-preorder-badge').empty();
    productContainer.find('.klarna-container').removeClass('d-none');
  }
};

/**
 *
 * @param {string} selectedValueUrl attribute URL
 * @param {Object} $productContainer product Detail container
 */
function attributeSelect(selectedValueUrl, $productContainer) {
  if ($productContainer.length && selectedValueUrl) {
    $body.trigger('product:beforeAttributeSelect', {
      url: selectedValueUrl,
      container: $productContainer
    });

    $.ajax({
      url: selectedValueUrl,
      method: 'GET',
      success: function (data) {
        handleVariantResponse(data, $productContainer, function () {
          updateOptions(data.product.options, $productContainer);
          updateQuantities(data.product.quantities, $productContainer);
          updateMarketPlace(data.product, $productContainer);
          // hide color text value if color select available
          const $pdpLabelWrapper = $('.pdp-label-wrapper');
          var isSelectColorExist = $pdpLabelWrapper && $pdpLabelWrapper.length && $pdpLabelWrapper.find('.js-color-swatch-wrapper');
          if (!isSelectColorExist || (isSelectColorExist && isSelectColorExist.length ===0)) {
            $('.attr-name .dropdown-hide-attribute').removeClass('d-none');
          }
          // update shipping option elements
          window.base.updateShippingOptions(data, $productContainer, true);
          base.preOrderBadge(data.product, $productContainer);

          if (data.product && data.product.badge && (data.product.badge.isFinalSale || data.product.isNotReturnable.value)) {
            $('span.final-sale-message', $productContainer).removeClass('d-none');
          } else if(!data.product.isNotReturnable.value && !data.product.badge.isFinalSale){
            $('span.final-sale-message', $productContainer).addClass('d-none');
          }
          try {
            var price = data.product.price.sales.value;
            var multiplier = $('.product-earn-points-message').data('multiplier');
            var points = Math.ceil(Number(price) * Number(multiplier));
            if (!isNaN(points) && points > 0) {
              $('.saks-points').empty().html(points);
            }
          } catch (e) {}

          if (window.dw &&
            window.dw.applepay &&
            window.ApplePaySession &&
            window.ApplePaySession.canMakePayments()) {

            const $applePayErrorMsgDiv = $('#applePayErrorMsgDiv');

            if($applePayErrorMsgDiv.length){
              $applePayErrorMsgDiv.addClass('d-none');
            }
            var applePayPDPButton = $('.pdp-apple-pay-button');
            var wishlistPDPButton = $('.wishlist-pdp-button');
            if (applePayPDPButton.length && wishlistPDPButton.length && !$('#completeTheLookDrawer:visible').length) {
              if(data.product.available){
                applePayPDPButton.removeClass('d-none');
                wishlistPDPButton.parent().addClass('applepay-wishlist');
                if(wishlistPDPButton.parent().hasClass('col-12')){
                  wishlistPDPButton.parent().removeClass('col-12').addClass('col-6');
                } else if(wishlistPDPButton.parent().hasClass('col-sm-12')){
                  wishlistPDPButton.parent().removeClass('col-sm-12').addClass('col-sm-6')
                }
              } else {
                applePayPDPButton.addClass('d-none');
                wishlistPDPButton.parent().removeClass('applepay-wishlist');
                if(wishlistPDPButton.parent().hasClass('col-6')){
                  wishlistPDPButton.parent().removeClass('col-6').addClass('col-12');
                } else if(wishlistPDPButton.parent().hasClass('col-sm-6')){
                  wishlistPDPButton.parent().removeClass('col-sm-6').addClass('col-12');
                }
              }
              if ($('.complete-the-look').length) {
                $('.modal-footer').find('.applepay-wishlist').removeClass('col-6').addClass('col-12');
              }
            }
          }

          const $waitListForm = $('form.waitlistForm');

          if ($waitListForm.is(':visible')) {
            floatLabel.resetFloatLabel();
            $waitListForm
              .find('input:visible')
              .each(function () {
                if ($(this).val() !== '') {
                  clientSideValidation.validateFormonBlur.call(this);
                }
              });
          }

          // update hudson reward point only for bay site
          if (data.product && data.product.hudsonPoint) {
            $('.product-detail').find('.reward-est-point').html(data.product.hudsonPoint);
          }
          $.spinner().stop();

          const $quantityField = $('.quantity-field');
          const $quantityFieldBtn = $quantityField.find('.btn-number');
          const $quantityFieldInput = $quantityField.find('.input-number');

          if($quantityFieldBtn.hasClass('mobile-border-active'))
            $quantityFieldBtn.removeClass('mobile-border-active');
          if($quantityFieldInput.hasClass('mobile-border-active'))
            $quantityFieldInput.removeClass('mobile-border-active');

          $body.trigger('product:afterAttributeSelect', {
            data: data,
            container: $productContainer
          });
        });
      },
      error: function () {
        $.spinner().stop();
      }
    });
  }
}
base.attributeSelect = attributeSelect;
base.colorAttribute = function () {
  $(document).on('click', '[data-attr="color"] button, [data-attr="color"] .slick-slide div button , [data-attr="Design"] button', function (e) {
    e.preventDefault();

    const $this = $(this);

    if ($this.attr('disabled')) {
      return;
    }
    var $productContainer = $this.closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $this.closest('.product-detail');
    }

    // If Color Swatch Dropdown is enabled
    if ($this.closest('.js-color-swatch-wrapper').length > 0 && $this.closest('.js-color-swatch-wrapper').find('select.select-color').length > 0) {
      var url = $this.attr('data-url');
      var $option = $this
        .closest('div.js-color-swatch-wrapper')
        .find('.select-color')
        .find('option[value="' + url + '"]');
      if ($option.length > 0) {
        $option.prop('selected', true).change();
      }
    } else {
      attributeSelect($this.attr('data-url'), $productContainer);
    }
  });

  $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
    e.preventDefault();

    const $this = $(this);
    var $productContainer = $this.closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $this.closest('.product-detail');
    }
    attributeSelect(e.currentTarget.value, $productContainer);
  });
};

base.selectAttribute = function () {
  $(document).on('click', 'li[data-attr-value] a', function (e) {
    e.preventDefault();
    const $this = $(this);
    const $listItem = $this.parent('li');
    var $productContainer = $this.closest('.set-item');
    if (!$productContainer.length) {
      $productContainer = $this.closest('.product-detail');
    }
    if ($listItem.length > 0 && $listItem.attr('selected') === 'selected') {
      return false;
    }
    attributeSelect($this.attr('data-href'), $productContainer);
  });
};

base.availability = function () {
  $(document).off('change', '.quantity-select');
  $(document).on('change', '.quantity-select', function (e , data) {
    e.preventDefault();
    // clear error message
    $('.cart-error').html('');

    const $this = $(this);
    var $productContainer = $this.closest('.product-detail');
    if (!$productContainer.length) {
      $productContainer = $this.closest('.modal-content').find('.product-quickview');
    }
    if ($('.bundle-items', $productContainer).length === 0 && (!data || (data && data.refreshSelect !== false))) {
      attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
    }
  });
};

base.initQuantity = function () {
  $body.on('click', '.btn-number', function (e) {
    e.preventDefault();
    $('.cart-error').html('');
    const $this = $(this);
    var fieldName = $this.attr('data-field');
    var type = $this.attr('data-type');
    var input = $this
      .closest('div')
      .find('input[name="' + fieldName + '"]');
    var currentVal = parseInt(input.val(), 10);
    if (!isNaN(currentVal)) {
      if (type === 'minus') {
        if (($this.attr('disabled') !== 'disabled') && (window.innerWidth < 1024 || window.isTouchscreen())) {
          $this.addClass('mobile-border-active');
          $this.closest('.quantity-field').find('.input-number').addClass('mobile-border-active');
        }
        if (currentVal === 1 && $this.closest('.mini-cart-container, .cart-page-content').length > 0) {
          input.val(currentVal - 1);
          $this.trigger('cart:removeitem', {
            uuid: input.data('uuid'),
            pid: input.data('pid'),
            url: input.data('remove-action')
          });
        } else {
          if (currentVal > parseInt(input.attr('min'), 10)) {
            input.val(currentVal - 1).change();
          }
          if (parseInt(input.val(), 10) === parseInt(input.attr('min'), 10)) {
            $this.attr('disabled', true);
          }
        }
        if ($this.attr('disabled') !== 'disabled') {
          $this.closest('.quantity-field').find('.input-number').addClass('active-border');
        }
      } else if (type === 'plus') {
        if (($this.attr('disabled') !== 'disabled') && (window.innerWidth < 1024 || window.isTouchscreen())) {
          $this.addClass('mobile-border-active');
          $this.closest('.quantity-field').find('.input-number').addClass('mobile-border-active');
        }

        input.val(currentVal + 1).change();
        if (parseInt(input.val(), 10) === parseInt(input.attr('max'), 10)) {
          $this.attr('disabled', true);
        }
        if ($this.attr('disabled') !== 'disabled') {
          $this.closest('.quantity-field').find('.input-number').addClass('active-border');
        }
      }
    } else {
      input.val(1);
    }
  });

  $body.on('blur', '.btn-number', function (e) {
    const $inputNumber = $('.input-number');
    if ($inputNumber.hasClass('active-border')) {
      $inputNumber.removeClass('active-border');
    }
  });

  $body.on('mouseenter focusin', '.input-number', function () {
    const $this = $(this);

    $this.data('oldValue', $this.val());
  });
  $body.on('change', '.input-number', function () {
    // clear error message
    $('.cart-error').html('');
    const $this = $(this);
    var minValue = parseInt($this.attr('min'), 10);
    var maxValue = parseInt($this.attr('max'), 10);
    var valueCurrent = parseInt($this.val(), 10);
    var name = $this.attr('name');
    if (valueCurrent >= minValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='minus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    } else {
      $this.val($this.data('oldValue'));
    }
    if (valueCurrent <= maxValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    }
    if (valueCurrent === 1) {
      $this
        .closest('div')
        .find(".btn-number[data-type='minus'][data-field='" + name + "']")
        .attr('disabled', true); // eslint-disable-line
    } else if (valueCurrent === maxValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .attr('disabled', true); // eslint-disable-line
    }
    // update Drop Down
    var currentQty = parseInt($this.val(), 10);
    $this.closest('div').find('select[class*="quantity-select"]').val(currentQty).trigger('change');
  });
  $body.on('keydown', '.input-number', function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
  $body.on('input', '.quantity-val', function () {
    const $this = $(this);
    var maxValue = parseInt($this.attr('max'), 10);
    if ($this.val() >= maxValue) {
      $('.cart-error').html('');
      $this.val(maxValue);
      $this.closest('div').find('select[class*="quantity-select"]').val(maxValue).trigger('change');
      $this.prev('span').find('button').removeAttr('disabled');
    }
  });
};

base.addToCart = function () {
  $(document).on('click', 'button.add-to-cart:not(".wishlist-tile-atc"), button.add-to-cart-global:not(".wishlist-tile-atc"), button.prdt_tile_btn:not(".wishlist-tile-atc")', function () {
    const $this = $(this);
    var addToCartUrl;
    var pid;
    var pidsObj;
    var setPids;
    var sourceage = $this.data('sourcepage');
    var atcOnTile = $this.data('view');
    var $productTile = $this.closest('.product-tile');
    var $productDetail = $this.closest('.product-detail');
    var $selectSizeColor = $productDetail.find('.select-size-color');
    var wlRemoveUrl = $productTile.find('.removefromwl').val();

    if (atcOnTile === 'tile') {
      var selectASize = $this.data('selectsizemsg');
      var addToCarLabel = $this.data('add-to-cart-label');
      const $prdtTileBtn = $productTile.find('button.prdt_tile_btn');

      $prdtTileBtn.text(selectASize).addClass('soldout disabled');

      setTimeout(function () {
        $prdtTileBtn.text(addToCarLabel).removeClass('soldout disabled').removeAttr('disabled');
      }, 3000);
    }

    var isReadyToOrder = $this.data('readytoorder');
    $selectSizeColor.empty();
    if (!isReadyToOrder) {
      var formData = {};
      formData.errorFields = ['no variant selected'];
      formData.formName = 'Add to Cart';
      $body.trigger('adobeTagManager:formError', formData);
      var sDiv = $('<div class="col-12 select-size-div" tabindex="0">');
      sDiv.html($this.data('readytoordertext'));

      if (window.FABuild && typeof window.FABuild.showCustomColorAndSizeSheet === 'function') {
        window.FABuild.showCustomColorAndSizeSheet();
      } else if (window.FABuild && typeof window.FABuild.BagEditShowCustomColorAndSizeSheet === 'function') {
        window.FABuild.BagEditShowCustomColorAndSizeSheet();
      }

      if ($('#missedattrSelection-ADA').length) {
        $('#missedattrSelection-ADA').html('Please select ' + getErrorFields() + ' to proceed');
      }
      $selectSizeColor.html(sDiv);
      if ($('.page').data('producttype') !== 'set') {
        $('.select-size-color').closest('.product-detail').find('.select-size-div').focus();
        $('.select-size-color')
          .closest('.product-detail')
          .find('.select-size-div')
          .on('keydown', function (e) {
            const $this = $(this);
            if (e.which === 9 && !e.shiftKey) {
              e.preventDefault();
              if (getErrorFields() == 'size') {
                $this
                  .closest('.attributes')
                  .find('div[data-attr=' + getErrorFields() + ']')
                  .find('.attribute ul li a')[0]
                  .focus();
              } else if (getErrorFields() == 'color') {
                $this
                  .closest('.attributes')
                  .find('div[data-attr=' + getErrorFields() + ']')
                  .find('.attribute ul li button')[0]
                  .focus();
              }
            }
          });
      }

      if ($productTile.length > 0 || !($this.data('producttype') != 'master')) {
        // eslint-disable-line
        // if the product is not a variant
        // do not attempt ajax call for atc. To reduce unnecessary
        return;
      }
      // If Product is not ready to Order,
      return;
    }

    $body.trigger('product:beforeAddToCart', this);

    if ($('.set-items').length && $this.hasClass('add-to-cart-global')) {
      setPids = [];

      $('.product-detail').each(function () {
        const $this = $(this);

        if (!$this.hasClass('product-set-detail')) {
          setPids.push({
            pid: $this.find('.product-id').text(),
            qty: $this.find('.quantity-select').val(),
            options: getOptions($this)
          });
        }
      });
      pidsObj = JSON.stringify(setPids);
    }
    pid = window.base.getPidValue($this);

    const $productQuickView = $this.closest('.product-quickview');
    const $removeFromWlQV = $productQuickView.find('.removefromwlQV')

    if ((wlRemoveUrl === null || wlRemoveUrl === undefined) && $productQuickView.length > 0) {
      var pidData = '?pid=' + pid;
      wlRemoveUrl = $removeFromWlQV.length > 0 ? $removeFromWlQV.val() : '';
      wlRemoveUrl += pidData;
    }
    var $productContainer = $this.closest('.product-detail');
    if (!$productContainer.length) {
      $productContainer = $this.closest('.quick-view-dialog').find('.product-detail');
    }

    addToCartUrl = getAddToCartUrl();
    // fetch the qty value on ATC, trigger change to ensure the purchase limit message is removed on interaction with ATC
    // It fetches the value from the qty input and sets it to qty select option
    var qtyValue = $productContainer.find('.quantity-val').val() || 1;
    $productContainer.find('select[class*="quantity-select"]').val(qtyValue).trigger('change', { refreshSelect: false });
    var form = {
      pid: pid,
      pidsObj: pidsObj,
      childProducts: getChildProducts(),
      quantity: !$this.closest('div').hasClass('hover-content') && base.getQuantitySelected($(this)) ? base.getQuantitySelected($(this)) : 1
    };

    if (!$('.bundle-item').length) {
      form.options = getOptions($productContainer);
    }
    $this.trigger('updateAddToCartFormData', form);

    // Show the Add to Bag modal.
    if (window.FABuild && typeof window.FABuild.showAddToBagModal === 'function') {
      window.FABuild.showAddToBagModal();
    }

    if (sourceage == 'pdp') {
      $this.addClass('ATC-addedToBag');
    }
    if (addToCartUrl) {
      $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function (data) {
          const $qvBasketLimitMsg = $productContainer.find('.qv-baskelimit-msg');
          const $pgName = $('.pg-name');

          // return and trigger a message product addition exceeded the basket preference limit
          if (data.message && data.message === 'LIMIT_EXCEEDED') {
            if ($productContainer.is('.product-quickview') && $qvBasketLimitMsg.length > 0) {
              $qvBasketLimitMsg.removeClass('d-none');
            } else {
              $body.trigger('triggerBasketLimitMsgModal');
            }
          } else {
            data.sourcepage = sourceage;
            var autoHideDialog = handlePostCartAdd(data);
            if (autoHideDialog) {
              $body.trigger('product:afterAddToCart', data);
            }
            // changes made to remove the product from wishlist of the same is being added from WL - Start
            if ($pgName.length > 0 && $pgName.val() === 'wishlist') {
              $.ajax({
                url: wlRemoveUrl,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                  window.location.reload();
                },
                error: function () {
                  // do nothing
                }
              });
            }
          }
          // changes made to remove the product from wishlist of the same is being added from WL - End
          $.spinner().stop();

          //For wishlist landing page to hide the edit modal after product add to cart
          const $editProductModal = $('#editProductModal');
          if ($('.wishlist-page').length > 0 && $editProductModal.length > 0) {
            $editProductModal.modal('hide');
          }
        },
        error: function () {
          $.spinner().stop();
        }
      });
    } else {
      $.spinner().stop();
    }
  });
};
base.methods = {
  editBonusProducts: function (data) {
    chooseBonusProducts(data);
  }
};
function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix) !== -1) {
        pars.splice(i, 1);
      }
    }

    url = urlparts[0] + '?' + pars.join('&');
    return url;
  } else {
    return url;
  }
}
base.checkProductAvailability = function (prodType, availUrl, context) {
  var $productContainer = $body.find('.product-detail') || context;
  if ($('.page').data('producttype') || prodType) {
    var productType = prodType || $('.page').data('producttype').toLowerCase();
    var isVariant = productType === 'variant' || productType === 'product';
    var isMaster = productType === 'master';
    var isSet = productType === 'set';
    var url;
    if (availUrl) {
      url = availUrl;
    } else {
      var availabilityurl = $('.page').data('availabilityurl');
      if (availabilityurl) {
        availabilityurl = removeURLParameter(availabilityurl, 'pid');
        url = availabilityurl + '&' + $('.page').data('querystring');
      }
    }
    var availabilityValue;
    const $addToCart = $productContainer.find('button.add-to-cart');
    const $addToCartGlobal = $productContainer.find('button.add-to-cart-global');
    const $purchaseLimit = $productContainer.find('.product-availability .purchaselimit');
    if (!isSet) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          if (isVariant) {
            var availabilityMessages = data.availability.messages;
            availabilityMessages.forEach(function (message) {
              var colorStyle = data.availability.hexColorCode;
              if (colorStyle) {
                colorStyle = 'style=color:' + colorStyle;
              }
              availabilityValue = '<li><div ' + colorStyle + '>' + message + '</div></li>';
            });

            $purchaseLimit.empty();
            if ($productContainer.hasClass('product-quickview')) {
              $addToCart.add($addToCartGlobal).attr('disabled', !data.product.available);
            } else {
              $('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            }
            if (data.product.readyToOrder && !data.product.availability.isInPurchaselimit) {
              $purchaseLimit.html(data.product.availability.isInPurchaselimitMessage);
              $purchaseLimit.attr('aria-describedby', 'purchase-limit');
              var formData = {};
              formData.errorFields = [data.product.availability.isInPurchaselimitMessage];
              formData.formName = 'Add to Cart';
              $body.trigger('adobeTagManager:formError', formData);
            }
            if ((!data.product.available || !data.product.readyToOrder) && data.product.productType !== 'master') {
              $productContainer.find('button.atc-button-pdp').addClass('soldout').text(data.resources.soldout);
              if ($productContainer.hasClass('product-quickview')) {
                addToCartGlobal.addClass('soldout').text(data.resources.soldout);
              }
            }
            $($productContainer).trigger('product:updateAvailability', {
              product: data.product,
              $productContainer: $productContainer,
              message: availabilityValue,
              resources: data.resources
            });
          } else if (isMaster) {
            updateAttrsForMaster(data.product.variationAttributes, $productContainer);
            if ($productContainer.hasClass('product-quickview')) {
              $addToCart.add($addToCartGlobal).attr('disabled', !data.product.available);
            } else {
              $('button.add-to-cart, button.add-to-cart-global').attr('disabled', !data.product.available);
            }
            if (!data.product.available) {
              $productContainer.find('button.atc-button-pdp').addClass('soldout');
              $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
              if ($productContainer.hasClass('product-quickview')) {
                $addToCartGlobal.addClass('soldout');
                $addToCartGlobal.text(data.resources.soldout);
              }
            }
          }
        },
        error: function () {
          $.spinner().stop();
        }
      });
    } else if (isSet) {
      $.ajax({
        url: url,
        method: 'GET',
        success: function (data) {
          data.product.individualProducts.forEach(function (item) {
            $productContainer = $body.find('.product-detail.set-item.' + item.id);
            if (item.productType === 'variant' || item.productType === 'product') {
              var availabilityMessages = item.availability.messages;
              availabilityMessages.forEach(function (message) {
                var colorStyle = item.availability.hexColorCode;
                if (colorStyle) {
                  colorStyle = 'style=color:' + colorStyle;
                }
                availabilityValue = '<li><div ' + colorStyle + '>' + message + '</div></li>';
              });
              $purchaseLimit.empty();
              $addToCart.add($addToCartGlobal).attr('disabled', !item.available);
              if (item.readyToOrder && !item.availability.isInPurchaselimit) {
                $purchaseLimit.html(item.availability.isInPurchaselimitMessage);
                $purchaseLimit.attr('aria-describedby', 'purchase-limit');
                var formData = {};
                formData.errorFields = [item.availability.isInPurchaselimitMessage];
                formData.formName = 'Add to Cart';
                $body.trigger('adobeTagManager:formError', formData);
              }
              if ((!item.available || !item.readyToOrder) && item.productType !== 'master') {
                $productContainer.find('button.atc-button-pdp').addClass('soldout').text(data.resources.soldout);
                if ($productContainer.hasClass('product-quickview')) {
                  $addToCartGlobal.addClass('soldout');
                  $addToCartGlobal.text(data.resources.soldout);
                }
              }
              $($productContainer).trigger('product:updateAvailability', {
                product: item,
                $productContainer: $productContainer,
                message: availabilityValue,
                resources: data.resources
              });
            } else if (item.productType === 'master') {
              $addToCart.add($addToCartGlobal).attr('disabled', !item.available);
              if (!item.available) {
                $productContainer.find('button.atc-button-pdp').addClass('soldout');
                $productContainer.find('button.atc-button-pdp').text(data.resources.soldout);
                if ($productContainer.hasClass('product-quickview')) {
                  $addToCartGlobal.addClass('soldout');
                  $addToCartGlobal.text(data.resources.soldout);
                }
              }
            }
          });
        },
        error: function () {
          $.spinner().stop();
        }
      });
    }
  }
};

base.addBonusProductsToCart = function () {
  $(document).on('click', '.add-bonus-products', function () {
    const $this = $(this);
    var $readyToOrderBonusProducts = $('input[name="chooseBonusproductIds"]:checked');
    var data = {};
    var url = $('.choose-bonus-product-dialog').data('addtocarturl');
    var pidsObject = {
      bonusProducts: []
    };

    $.each($readyToOrderBonusProducts, function () {
      const $this = $(this);
      var qtyOption = 1;

      var option = null;
      if (qtyOption > 0) {
        if ($this.data('optionid') && $this.data('option-selected-value')) {
          option = {};
          option.optionId = $this.data('optionid');
          option.productId = $this.data('pid');
          option.selectedValueId = $this.data('option-selected-value');
        }
        pidsObject.bonusProducts.push({
          pid: $this.data('pid'),
          qty: qtyOption,
          options: [option]
        });
        pidsObject.totalQty = parseInt($('.pre-cart-products').length > 0 ? $('.pre-cart-products').html() : 1, 10);
      }
    });
    // Adding product from details
    if ($this.hasClass('from-modal')) {
      pidsObject.bonusProducts = [];
      pidsObject.bonusProducts.push({
        pid: $this.data('pid'),
        qty: 1,
        options: null
      });
    }

    const $chooseBonusProductDialog = $('.choose-bonus-product-dialog');

    data = {
      pids: JSON.stringify(pidsObject),
      uuid: $chooseBonusProductDialog.data('uuid'),
      pliuuid: $chooseBonusProductDialog.data('pliuuid')
    };
    $.spinner().start();
    $.ajax({
      url: url,
      data: data,
      method: 'POST',
      success: function (response) {
        $.spinner().stop();
        if (response.error) {
          const $errorBlock = $('.choice-of-bonus-product .error-div');
          $errorBlock.html('<div class="alert alert-danger add-to-basket-alerts text-center">');
          $errorBlock.find('.add-to-basket-alerts').html(response.errorMessage);
        } else {
          $body.trigger('adobe:selectbonusProduct', response);
          $('.configure-bonus-product-attributes').html(response);
          $('.bonus-products-step2').removeClass('hidden-xl-down');
          $('#chooseBonusProductModal').modal('hide');
          $('#editProductModal').modal('hide');
          location.reload();
        }
      },
      error: function () {
        $.spinner().stop();
      }
    });
  });
};
module.exports = base;
