module.exports = {
  /**
   * Creates a document fragment out of an HTML string
   * @param {String} htmlStr A string of HTML
   * @returns a Document Fragment representing the HTML string
   */
  createFragment: function (htmlStr) {
    return document.createRange().createContextualFragment(htmlStr.trim());
  },

  /**
   * Creates a string out of a document fragment. Strips out comment nodes.
   * @param {Object} fragment A document fragment
   * @returns an HTML string representing the document fragment.
   */
  fragmentToString: function (fragment) {
    const nodes = [].slice.call(fragment.childNodes); // Array from the node list.
    return nodes
      .filter(node => node.nodeType !== 8) // Strip out comments
      .reduce((result, node) => result + (node.outerHTML || node.nodeValue), ''); // Convert it to a string.
  }
};
