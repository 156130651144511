'use strict';

var base = {};
var baseBase = require('./base');
var persistentWishlist = require('core/product/persistentWishlist');

/** Constants */
const $body = $('body');
const $html = $('html');

/**
 * Enable/disable UI elements
 * @param {boolean} enableOrDisable - true or false
 */
function updateAddToCartEnableDisableOtherElements(enableOrDisable) {
  $('button.add-to-cart-global').attr('disabled', enableOrDisable);
}

base.methods = {
  updateAddToCartEnableDisableOtherElements: updateAddToCartEnableDisableOtherElements
};

base.focusChooseBonusProductModal = baseBase.focusChooseBonusProductModal();
base.activateVideoPlayer = baseBase.activateVideoPlayer;
base.addToCart = baseBase.addToCart;
base.tooltipInit = window.hbcTooltip.tooltipInit;
base.activatePrimarySlick = baseBase.activatePrimarySlick;

base.updateAttributesAndDetails = function () {
  $('body').on('product:statusUpdate', function (e, data) {
    var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');
    const $descriptionAndDetail = $productContainer.find('.description-and-detail');
    const $description = $descriptionAndDetail.find('.description');
    const $details = $descriptionAndDetail.find('.details');
    const $enableTruefit = $('.enableTruefit');

    $descriptionAndDetail.find('.product-attributes').html(data.attributesHtml);

    if (data.shortDescription) {
      $description.removeClass('hidden-xl-down');
      $description.find('.content').html(data.shortDescription);
    } else {
      $description.addClass('hidden-xl-down');
    }

    if (data.longDescription) {
      $details.removeClass('hidden-xl-down');
      $details.find('.content').html(data.longDescription);
    } else {
      $details.addClass('hidden-xl-down');
    }
    if ($enableTruefit.length > 0 && $enableTruefit.val() === 'true') {
      tfcapi('calculate'); // eslint-disable-line
    }
  });
};

base.swatchesEvents = function () {
  $body.on('click', '.sizeSwatch', function (e) {
    e.preventDefault();

    const $this = $(this);
    const $productTile = $this.closest('.product-tile');

    if ($this.hasClass('selected')) {
      return;
    }
    var $prevSelectedSize = $this.closest('.size').find('.sizeSwatch.selected');
    $prevSelectedSize.removeClass('selected');
    $this.addClass('selected');
    $productTile.find('.prdt_tile_btn').data('view', 'selected');
    attributeSelect($this.data('valueurl'), $productTile);
  });
  $body.on('click', '.color-swatches .swatches .colorswatch', function (e) {
    e.preventDefault();

    const $this = $(this);

    if ($this.hasClass('selected')) {
      return;
    }

    var $tile = $this.closest('.product-tile');
    $tile.find('.color-swatches .swatches .colorswatch.selected').each(function () {
      $(this).removeClass('selected');
    });
    $this.addClass('selected');
    var color = $this.data('attr-value');
    $tile.find(".color-swatches .swatches .colorswatch[data-attr-value='" + color + "']").addClass('selected');
    $tile.find('.image-container').removeClass('prevent-slick');
    $tile.find('.thumb-link').attr('href', $this.attr('href'));
    $tile.find('.link').attr('href', $this.attr('href'));
    var data = $this.find('.swatch-circle').data('imgsource');
    var oldImgs = [];
    $tile.find('.image-container .thumb-link img').each(function () {
      oldImgs.push($this);
    });
    if ($this.data('qvurl')) {
      $tile.find('.image-container .quickview').attr('href', $this.data('qvurl'));
    }
    const $thumblink = $tile.find('.image-container .thumb-link');
    let imgHTML = '';

    for (const element of data) {
      var setImg = element;
      imgHTML += '<img class="tile-image" itemprop="image" src="' + setImg.url + '" title="' + setImg.title + '" alt="' + setImg.alt + '"/>';
    }

    $thumblink.append(imgHTML);
    attributeSelect($this.data('valueurl'), $this.closest('.product-tile'), oldImgs);
  });
  /* eslint-disable */
  if ($(window).width() > 1023.99) {
    $body
      .on('mouseenter', '.thumb-link', function () {
        let initialIndex = 0;
        const $this = $(this);
        const $productTile = $this.closest('.product-tile');
        const $selectableIndex = $productTile.find('.firstSelectableIndex');
        const $size = $productTile.find('.size');
        const $imageContainer = $this.closest('.image-container');
        if ($selectableIndex.length > 0) {
          initialIndex = $selectableIndex.eq(0).attr('data-firstSelectableIndex');
        }
        if (!$size.hasClass('slick-initialized')) {
          $size.slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            autoplay: false
          });

          if ($size.eq(0).find('.slick-slide').length - 1 == Number(initialIndex)) {
            $size.slick('slickGoTo', Number(initialIndex) - 1, true);
          } else {
            $size.slick('slickGoTo', Number(initialIndex), true);
          }
        }
        if ($imageContainer.hasClass('prevent-slick')) {
          return false;
        }
        if ($this.hasClass('slick-initialized')) {
          $this.slick('unslick');
        }
        $this
          .find('img')
          .each(function () {
            const $this = $(this);
            $this.attr('src', $this.attr('data-src'));
          });
        if (!/msie\s|trident\/|Edge\//i.test(navigator.userAgent)) {
          $this.slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 500
          });
        }
        if ($imageContainer.find('.tile-image').hasClass('guest-user')) {
          $this.slick('unslick');
        }
      })
      .on('mouseleave', '.thumb-link', function () {
        const $this = $(this);

        if ($this.hasClass('slick-initialized')) {
          $this.slick('unslick');
        }
      });
    /* eslint-enable */

    $body.on('mouseenter', '.product-tile', function () {
      let initialIndex = 0;
      const $this = $(this);
      const $firstSelectableIndex = $this.find('.firstSelectableIndex');
      const $size = $this.find('.size');

      if ($firstSelectableIndex.length > 0) {
        initialIndex = $firstSelectableIndex.eq(0).attr('data-firstSelectableIndex');
      }
      if (!$size.hasClass('slick-initialized')) {
        $size.slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false
        });

        if ($size.eq(0).find('.slick-slide').length - 1 == Number(initialIndex)) {
          $size.slick('slickGoTo', Number(initialIndex) - 1, true);
        } else {
          $size.slick('slickGoTo', Number(initialIndex), true);
        }
      }
    });
  }
}

base.turntoReviewBlockObserver = function () {
  try {
    var prodReviewsDiv = 'div#tt-reviews-summary';
    var prodReviewsContainer = '.tt-w-reviews-summary';
    var pdpReviewsBlock = '.product-details-page .pdp-reviews';
    $(document).off("DOMNodeInserted", prodReviewsDiv).on("DOMNodeInserted", prodReviewsDiv , function (evt) {
      evt.stopPropagation();
      var hasNoneClass = $(pdpReviewsBlock).hasClass('d-none');
      var ttReviews = $(prodReviewsDiv +' '+ prodReviewsContainer);
      var ttReviewsData = $(ttReviews).children();
      if (hasNoneClass && (ttReviewsData && ttReviewsData.length > 0)) {
        $(pdpReviewsBlock).removeClass('d-none');
      }
    });
  } catch(e) {
    console.log(e);
  }
};
$(document).ready(function () {
  var $changeZipPdpWrapper = $('.change-zip-pdp-wrapper');
  var changeZipPdpWrapperURL = $changeZipPdpWrapper.data('url');

  if (changeZipPdpWrapperURL) {
    $.ajax({
      url: changeZipPdpWrapperURL,
      type: 'get',
      success: function (data) {
        if (data) {
          $changeZipPdpWrapper.append(data);
        }
      }
    });
  }

  var $productContainer = $body.find('.product-detail');
  var wishlistPDPOnLoad = $productContainer.data('load-wishlist-ajax');
  if (wishlistPDPOnLoad) {
    var pid = $productContainer.data('pid');
    persistentWishlist.makrSingleProductWishlisted(pid , $productContainer);
  }

  if (window.Urls &&
    window.Urls.updateApplePayData &&
    window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments()) {
      $('#shipto').click(function(e, el) {
          $.spinner().start();
          $.ajax({
            url: window.Urls.updateApplePayData,
            complete: function (data) {
              //getRequest call needed to prepare the payment sheet request body after changing shipping modes (ship to/pickup)
              $.ajax({url: window.dw.applepay.action.getRequest});
              setTimeout(function () {
                $.spinner().stop();
              }, 1500);
            }
          });
      });
      $('#instore').click(function(e, el) {
        $.spinner().start();
        $.ajax({
          url: window.Urls.updateApplePayData + '?bopis=true',
          complete: function (data) {
            //getRequest call needed to prepare the payment sheet request body after changing shipping modes (ship to/pickup)
            $.ajax({url: window.dw.applepay.action.getRequest});
            setTimeout(function () {
              $.spinner().stop();
            }, 1500);
          }
        });
    });
  }

   // call to order click event
   $('.product-detail .call-to-order').on('click', function() {
    $('body').trigger('adobeTagManager:callToOrder');
  })

  // criteo ajax call
  if (window.SitePreferences.criteoEnabled) {
    let criteoAPI_CallEnabledPages = window.SitePreferences.criteoAPI_CallEnabledPages;
    if (criteoAPI_CallEnabledPages && criteoAPI_CallEnabledPages.length > 0 && criteoAPI_CallEnabledPages.indexOf('productDetail') > -1) {
      let criteo = require('criteo/criteo/criteoProducts');
      criteo.sendDataToCriteo();
    }
  }

});

// To override complete hiding of recommendation on the chanel from core detail.js
base.showChanelRecommendations = function () {};
base.updateAddToCart = function () {
  $('body').on('product:updateAddToCart', function (e, response) {
    // update local add to cart (for sets)
    var $productContainer = response.$productContainer;
    var $buttonAddToCart = $productContainer.find('button.add-to-cart');
    const $waitListForm = $productContainer.find('.js-wait-list-form');
    if (response.$productContainer && response.$productContainer.hasClass('set-item')) {
      $buttonAddToCart = response.$productContainer.find('button.add-to-cart');
    }
    $buttonAddToCart.data('readytoorder', response.product.readyToOrder && response.product.orderableNotInPurchaselimit);
    $buttonAddToCart.data('readytoordertext', response.product.readyToOrderMsg);
    if (
      response.product.waitlistable &&
      response.product.availability.ats <= 0 &&
      response.product.productType !== 'master' &&
      (!response.product.readyToOrder || !response.product.available)
    ) {
      if ($('.page').data('producttype') === 'set') {
        var $productContainer = response.$productContainer;
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').addClass('d-none');
        $productContainer.find('.js-product-availability-qty').addClass('d-none');
        $waitListForm.removeClass('d-none');
        $waitListForm.find('.waitlist-product-id').val(response.product.id);
        $productContainer.find('.wait-list-success').empty();
      } else {
        //$('.js-add-to-cart').addClass('d-none');
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').addClass('d-none');
        $productContainer.find('.js-product-availability-qty').addClass('d-none');
        $waitListForm.removeClass('d-none');
        $waitListForm.find('.waitlist-product-id').val(response.product.id);
        $productContainer.find('.wait-list-success').empty();
      }
      window.formFields.adjustForAutofill();
    } else {
      if ($('.page').data('producttype') === 'set') {
        if (
          response &&
          response.product &&
          response.product.waitlistable &&
          response.product.availability.ats <= 0 &&
          response.product.productType !== 'master' &&
          (!response.product.readyToOrder || !response.product.available)
        ) {
          var $productContainer = response.$productContainer;
          $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').addClass('d-none');
          $productContainer.find('.js-product-availability-qty').addClass('d-none');
          $waitListForm.removeClass('d-none');
          $waitListForm.find('.waitlist-product-id').val(response.product.id);
          $productContainer.find('.wait-list-success').empty();
        }
      } else {
        if(!response.product.limitedVarinatQtyUpdated) {
          $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').removeClass('d-none');
          $productContainer.find('.js-waitlist-wrapper').addClass('d-none');
          $waitListForm.addClass('d-none');
          $productContainer.find('.js-product-availability-qty').removeClass('d-none');
          $productContainer.find('.wait-list-success').empty();
        }
      }
    }
    /* var enable = $('.product-availability').toArray().every(function (item) {
            return $(item).data('available') && $(item).data('ready-to-order');
        }); */
    if ($('.page').data('producttype') === 'set') {
      const $addToCart = $('button.add-to-cart', response.$productContainer);

      if(!response.product.limitedVarinatQtyUpdated) {
        $addToCart.text(response.product.availability.buttonName);
        $('button.add-to-cart-global', response.$productContainer).text(response.product.availability.buttonName);
      }
      $addToCart.attr('disabled', !response.product.available && response.product.availability.isInPurchaselimit);
      $($productContainer.find('div [id^="collapsible-details-"] .product-detail-id'), response.$productContainer).html(response.product.longDescriptionStyle);
    } else {
      // change button text only if pick up is not selected;
      const $shippingOption = $('.shipping-option');
      const $shippingOptionInput = $shippingOption.find('input:checked');

      if (
        !(
          $shippingOption.length > 0 &&
          $shippingOptionInput.length > 0 &&
          $shippingOptionInput.val().length > 0 &&
          $shippingOptionInput.val() === 'instore'
        )
      ) {
        if (response.product.preOrder && response.product.preOrder.applicable && response.product.preOrder.applicable === true) {
          $buttonAddToCart.addClass('atc-btn-preorder');
          $buttonAddToCart.text(response.product.preOrder.preorderButtonName);
          if (response.product.preOrder.shipDate) {
            if ($body.hasClass('product-details-page')) {
              $('div .preorder-ship-date').html("<span class='preorder-text'>" + response.product.preOrder.shipDate + '</span>' + response.product.preOrder.tooltip);
              window.hbcTooltip.tooltipInit();
            } else {
              $('div .preorder-ship-date').html("<span class='preorder-text'>" + response.product.preOrder.shipDate + '</span>');
            }
          }
          $('.shipping-option').addClass('d-none');
        } else {
          $buttonAddToCart.removeClass('atc-btn-preorder');
          if(!response.product.limitedVarinatQtyUpdated) {
            $buttonAddToCart.text(response.product.availability.buttonName);
          }
          $('div .preorder-ship-date').empty();
          $('.shipping-option').removeClass('d-none');
        }
      }
      $buttonAddToCart.attr('disabled', !response.product.available && response.product.availability.isInPurchaselimit);
      if (response.product.preOrder && response.product.preOrder.applicable && response.product.preOrder.applicable === true) {
        $('button.add-to-cart-global').text(response.product.preOrder.preorderButtonName);
        if (response.product.preOrder.shipDate) {
          if($body.hasClass('product-details-page')) {
            $('div .preorder-ship-date').html("<span class='preorder-text'>" + response.product.preOrder.shipDate + '</span>' + response.product.preOrder.tooltip);
            window.hbcTooltip.tooltipInit();
          } else {
            $('div .preorder-ship-date').html("<span class='preorder-text'>" + response.product.preOrder.shipDate + '</span>');
          }
        }
        $('.shipping-option').addClass('d-none');
      } else {
        if(!response.product.limitedVarinatQtyUpdated) {
          $('button.add-to-cart-global').text(response.product.preOrder.preorderButtonName);
        }
        $('div .preorder-ship-date').empty();
        $('.shipping-option').removeClass('d-none');
        var isWaitListVisible = $('.product-secondary-section form.waitlistForm:visible').length > 0;
        if (isWaitListVisible) {
          $('.shipping-option').addClass('d-none');
        }
      }
      $productContainer.find('div [id^="collapsible-details-"] .product-detail-id').html(response.product.longDescriptionStyle);
    }
  });
};
base.submitWaitList = function () {
  $body.on('submit', 'form.waitlistForm', function (e) {
    var form = $(this);
    e.preventDefault();
    var url = form.attr('action');
    form.spinner().start();
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: form.serialize(),
      success: function (data) {
        var $productContainer = $(form).closest('.product-detail');
        const $waitListForm = $productContainer.find('.waitlistForm');
        if (data.success) {
          $productContainer
            .find('.wait-list-success')
            .removeClass('d-none')
            .empty()
            .html('<div class="alert-success"><div class="success-msg"><span class="message">' + data.msg + '</span></div></div>');
          $productContainer.find('.js-wait-list-form').addClass('d-none');
          $waitListForm[0].reset();
          $waitListForm.find('.input-focus').removeClass('input-focus');
          $waitListForm.find('input').next('span').remove();
          $body.trigger('adobe:waitListComplete', data);
          $("input[name='waitlistEmail']").val(data.email);
        } else {
          $productContainer.find('.wait-list-success').html("<div class='alert-success'>" + data.msg + '</div>');
        }
        form.spinner().stop();
      },
      error: function (data) {
        $productContainer
          .find('.wait-list-success')
          .removeClass('d-none')
          .html("<div class='alert-success'>" + data.msg + '</div>');
        form.spinner().stop();
      }
    });
    return false;
  });
};

base.buttonToggle = function () {};

$(document).on('click', '.product-details-page .custom-color-dropdown .selected-option', function () {
  if (window.innerWidth < 544) {
    //stop body from scrolling when opening the color list
    $body.addClass('select-opened');
    var pageTop = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.top = pageTop;
  }
});

$(document).on('click', '.product-details-page .custom-color-dropdown .selection-list', function () {
  if (window.innerWidth < 544) {
    $body.removeClass('select-opened');
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
});
base.closeColorDropDown = function () {
  $(document).on('click', '.custom-color-dropdown .selection-list', function () {
    if (window.innerWidth < 544) {
      $(this).closest('.current_item').removeClass('current_item');
    }
  });
};

base.displayWaitListOptMsg = function () {
  $body.on('focus keyup', '.js-waitlist-mobile, .js-waitlist-email', function () {
    const $this = $(this);
    const $form = $this.closest('.waitlistForm');
    const $waitListMobile = $form.find('.js-waitlist-mobile');
    const $mobileOptMsg = $form.find('.js-mobile-opt-msg');

    if ($waitListMobile.val() !== '' || $form.find('.js-waitlist-email').val() !== '') {
      $mobileOptMsg.removeClass('d-none');
      if ($waitListMobile.val() !== '') {
        $waitListMobile.attr('required', true);
        $waitListMobile.attr('pattern', $this.attr('data-pattern'));
      } else {
        $waitListMobile.removeAttr('required');
        $waitListMobile.removeAttr('pattern');
      }
    } else {
      $mobileOptMsg.addClass('d-none');
      if ($waitListMobile.val() == '') {
        $waitListMobile.removeAttr('required');
        $waitListMobile.removeAttr('pattern');
      }
    }
  });
  $body.on('blur', '.js-waitlist-mobile', function () {
    if ($(this).val() == '') {
      const $waitlistMobile = $('.js-waitlist-mobile');
      const $formGroup = $waitlistMobile.closest('.form-group');

      $waitlistMobile
          .removeAttr('required')
          .removeAttr('pattern')
          .removeClass('is-invalid')
          .next('span').remove();
      $formGroup.find('.invalid-feedback').empty();
      $formGroup.find('label').removeClass('is-invalid input-focus');
    }
  });
};

base.showSpinner = function () {
  $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function (evt) {
    if (evt.target.classList.contains('modal-open')) {
      return;
    }
    $.spinner().start();
  });
};

function isDesktop() {
  // base this on when PDP changes from desktop/tablet to mobile layout - not screen width
  return window.innerWidth >= 544;
}

base.reviewsMobilePlacement = function () {
  if (!isDesktop()) {
    if ($('.pdp-right-section').find('.product-number-rating').length) {
      var review = $('.product-number-rating').clone();
      $('.product-detail').find('.mobile-product-brand-name').append(review);
    }
  }
};

/**
 * PDP Size chart model actions.
 **/

var fillSizeGuideModal = function ($modal) {
  var $guideContent = $modal.find('.guide-content');
  var url = $guideContent.attr('data-url');
  var hasLoadedGuideContent = $guideContent.children().length > 0;
  if (!hasLoadedGuideContent) {
    $guideContent.spinner().start();
    $.ajax({
      url: url,
      method: 'GET',
      dataType: 'json',
      success: function (responseData) {
        if (responseData.success) {
          $guideContent.html(responseData.markup || '');
          let $tableHeader = $modal.find('.size-guide__tabs--content .size-guide__table--header');
          sizeCartStickyModal($tableHeader, $modal);
        }
        $guideContent.spinner().stop();
      },
      error: function () {
        $guideContent.spinner().stop();
      }
    });
  }
}

let sizeCartStickyModal = function($tableHeader, $modal) {
  const $tableHeaderChildren = $tableHeader && $tableHeader.children();

  if ($tableHeaderChildren && $tableHeaderChildren.length > 3) {
    $modal.addClass('stickyColumn');
  } else {
    $modal.removeClass('stickyColumn');
  }
}

base.sizeChartModel = function () {
  $('.size_guide').on('click', 'button', function (e) {
    e.preventDefault();
    var masterID = $(this).closest('.size_guide').attr('data-masterid');
    var $sizeModal = $('.size-modal');
    const sizeGuideMasterId = $("[data-sizeguidemasterid='" + masterID + "']");
    if (masterID && masterID != '' && sizeGuideMasterId.length > 0) {
      $sizeModal = sizeGuideMasterId;
    }

    $sizeModal.modal('show');
    fillSizeGuideModal($sizeModal);
  });
};

/**
 * code to enable native share functionality in devices which supports.
 */
base.shareThis = function () {
  var $windowMedia = window.matchMedia('(max-width: 1025px)').matches;
  $('.social-share').on('click', function () {
    if ($windowMedia) {
      const url = window.location.href;
      const dataHead = document.title;
      const text = $(this).attr('data-social-share');
      if (navigator.share !== undefined) {
        navigator
          .share({
            title: dataHead,
            text: text,
            url: url
          })
          .then(() => {
            // See if the user copied the link from the share sheet.
            navigator.clipboard.readText().then(clipText => {
              if (clipText.includes(url)) {
                // Pare the copied text just down to the URL.
                navigator.clipboard.writeText(url);
              }
            });
          });
      }
    }
  });
};

/**
 * Prepare html modal for appointment
 * @param {Object} obj - object containing the data
 *
 **/
function getAppointmentModalHtmlElement(obj) {
  const $appointmentModal = $('#appointmentModal');
  const $customerDetails = $('.customer-details');

  if ($appointmentModal.length !== 0) {
    $appointmentModal.remove();
  }
  // eslint-disable-next-line no-param-reassign
  obj.firstName = $customerDetails.data('customer').firstName ? $customerDetails.data('customer').firstName : '';
  // eslint-disable-next-line no-param-reassign
  obj.lastName = $customerDetails.data('customer').lastName ? $customerDetails.data('customer').lastName : '';
  // eslint-disable-next-line no-param-reassign
  obj.phone = $customerDetails.data('customer').phone ? $customerDetails.data('customer').phone : '';
  // eslint-disable-next-line no-param-reassign
  obj.email = $customerDetails.data('customer').email ? $customerDetails.data('customer').email : '';
  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade time-trade-dialog" id="appointmentModal" role="dialog">' +
    '<div class="modal-dialog">' +
    '<!-- Modal content-->' +
    '<div class="modal-content row no-gutters align-items-center justify-content-center text-center"><button type="button" class="close svg-svg-22-cross-dims svg-svg-22-cross" data-dismiss="modal" aria-label="Close"></button>' +
    '<div class="modal-header-content">' +
    '    <div class="header-text">' +
    obj.resources.header +
    '</div>' +
    '</div><div class="header-info">' +
    obj.resources.headerInfo +
    '</div>' +
    '<div id="appointmentForm" class="modal-body">' +
    '<form class="appointment-form" action="' +
    obj.url +
    '" method="post" target="_blank">' +
    '<div class="form-group form-input floating-label required"><label class="form-control-label">' +
    obj.resources.firstName +
    '</label>' +
    '<input type="text" name="attendee_person_firstName" required max-length="50" class="form-control required" id="firstName" data-missing-error="' +
    obj.resources.requiredMsg +
    '" value="' +
    obj.firstName +
    '"/><div class="invalid-feedback"></div></div>' +
    '<div class="form-group form-input floating-label required"><label class="form-control-label">' +
    obj.resources.lastName +
    '</label>' +
    '<input type="text" name="attendee_person_lastName" required  max-length="50"data-missing-error="' +
    obj.resources.requiredMsg +
    '" class="form-control" id="lastName" value="' +
    obj.lastName +
    '"/><div class="invalid-feedback"></div></div>' +
    '<div class="form-group form-input floating-label required"><label class="form-control-label">' +
    obj.resources.email +
    '</label>' +
    '<input type="email" name="attendee_email" required max-length="50" aria-describedby="form-email-error" aria-required="true" data-missing-error="' +
    obj.resources.requiredMsg +
    '" class="form-control" pattern="^[\\w.%+-]+@[\\w.-]+\\.[\\w]{2,6}$" data-pattern-mismatch="' +
    obj.resources.emailInvalidMsg +
    '" id="email" value="' +
    obj.email +
    '"/><div class="invalid-feedback"></div></div>' +
    '<div class="form-group form-input floating-label required"><label class="form-control-label">' +
    obj.resources.phone +
    '</label>' +
    '<input type="text" name="attendee_phone_phoneNumber" data-missing-error="' +
    obj.resources.requiredMsg +
    '" required pattern="^\\(?([2-9][0-8][0-9])\\)?[\\-\\. ]?([2-9][0-9]{2})[\\-\\. ]?([0-9]{4})(\\s*x[0-9]+)?$" class="form-control" id="phoneNumber" value="' +
    obj.phone +
    '" /><div class="invalid-feedback"></div></div>' +
    '<input type="hidden" name="locationId" value="' +
    obj.locationID +
    '"/>' +
    '<input type="hidden" name="appointmentTypeId" value="' +
    obj.appointmentTypeID +
    '"/>' +
    '<div class="row appointment-form-btns"><div class="col-12 col-sm-6 appointment-form-btn-left"><button type="button" data-dismiss="modal" class="btn btn-secondary btn-block">' +
    '' +
    obj.resources.cancelLabel +
    '' +
    '</button></div><div class="col-12 col-sm-6 appointment-form-btn-right"><button type="submit" id="submitAppointment" class="btn btn-primary btn-block">' +
    obj.resources.continueLabel +
    '</button></div></div>' +
    '</form>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $body.append(htmlString);
  if (obj.email !== '') {
    window.formFields.adjustForAutofill();
  }
  $('form.appointment-form').submit(function () {
    setTimeout(function () {
      $('.appointment-form-btn-left button').trigger('click');
    }, 100);
  });
}

base.initiateTimeTradeEvents = function () {
  $('button.schedule-an-appointment.enabled').on('click', function (e) {
    e.preventDefault();

    var obj = $(this).data('timetradejson'); // + 'app/hbc/workflows/HBC001/schedule/';
    getAppointmentModalHtmlElement(obj);
    setTimeout(function () {
      window.formFields.adjustForAutofill();
    }, 200);
    clientSideValidation.submit();
    clientSideValidation.invalid();
    clientSideValidation.buttonClick();
  });
};

/**
 * PDP set go to first product
 *
 **/
base.pdpSetGoTo = function () {
  $('.shop-collection').on('click', function () {
    $html.add($body).animate(
      {
        scrollTop: $($('.product-set-detail').find('.set-item')[0]).offset().top - 100
      },
      500
    );
  });
};

base.waitlistAnchor = function () {
  $(document).ready(function () {
    var url = window.location.href;
    var query = url.split('#')[1];
    if (query !== '' && query == 'waitlistenabled') {
      scrollAnimate($('#waitlist'));
    }
  });
};

base.updateAvailability = function () {
  $('body').on('product:updateAvailability', function (e, response) {
    const $globalAvailability = $('.global-availability');

    $('div.availability', response.$productContainer).data({
      'ready-to-order': response.product.readyToOrder,
      'available': response.product.available
    })
    $('.availability-msg', response.$productContainer).html(response.message);

    if ($globalAvailability.length) {
        const $productAvailabilityArray = $('.product-availability').toArray();
        const allAvailable = $productAvailabilityArray.every(function (item) { return $(item).data('available'); });
        const allReady = $productAvailabilityArray.every(function (item) { return $(item).data('ready-to-order'); });

        $globalAvailability.data({
          'ready-to-order': allReady,
          'available': allAvailable
        })
        $globalAvailability.find('.availability-msg').html(allReady ? response.message : response.resources.info_selectforstock);
    }
  });
};

base.sizeChart = function () {
  $('.size-chart a').on('click', function (e) {
      e.preventDefault();
      const $this = $(this);
      var url = $this.attr('href');
      var $prodSizeChart = $this.closest('.size-chart').find('.size-chart-collapsible');
      if ($prodSizeChart.is(':empty')) {
          $.ajax({
              url: url,
              type: 'get',
              dataType: 'json',
              success: function (data) {
                  $prodSizeChart.append(data.content);
              }
          });
      }
      $prodSizeChart.toggleClass('active');
  });

  var $sizeChart = $('.size-chart-collapsible');
  $body.on('click touchstart', function (e) {
      if ($('.size-chart').has(e.target).length <= 0) {
          $sizeChart.removeClass('active');
      }
  });
};

base.copyProductLink = function () {
  $body.on('click', '#fa-link', function () {
      const $copyLinkMessage = $('.copy-link-message');
      const $temp = $('<input>');
      $body.append($temp);
      $temp.val($('#shareUrl').val()).select();
      document.execCommand('copy');
      $temp.remove();
      $copyLinkMessage.attr('role', 'alert');
      $copyLinkMessage.removeClass('d-none');
      setTimeout(function () {
        $copyLinkMessage.addClass('d-none');
      }, 3000);
  });
};

/**
 * PDP scene 7 image zoom window
 *
 * @param {params} params - Params for scene 7
 * @param {product} pid - Product id
 **/
var enableScene7Zoom = function (params, pid) {
  if ($('.enablePDPZoomer').length > 0) {
    var prodID = pid || $('div.s7-viewer').attr('data-productID');
    var zoomViewer = new s7viewers.FlyoutViewer({
      // eslint-disable-line
      containerId: 's7viewer-' + prodID, // eslint-disable-line
      params: params // eslint-disable-line
    }).init();
  }
};

var isPrimaryImageZoomActivated = false;

/**
 * PDP activate zoom window, slider on zoom window, call scene 7 zoom functionality
 *
 * @param {element} $element - Slick element
 * @param {product} pid - Product id
 * @param {slide} currentSlide - Current slide
 **/
var activateZoomSlick = function ($element, pid, currentSlide) {
  var serverUrl = $element.find('.pdp-carousel').data('scene7hosturl');
  var asset;
  var imageEl;
  var image;
  var params = {};

  imageEl = $element.find('.thumb-nail').eq(currentSlide).find('img');

  if (!imageEl.length) {
    imageEl = $element.prevObject
  }

  image = imageEl.attr('src') ? imageEl.attr('src').split('image/') : imageEl.data('src').split('image/');
  asset = image[image.length - 1];
  params.asset = asset;
  params.serverurl = serverUrl;
  $('.s7-viewer').empty();
  enableScene7Zoom(params, pid);
};

/**
 * PDP activate primary selected image zoom
 *
 * @param {this} $this - current element
 **/
var activatePrimaryImageZoom = function ($this) {
  var $element = $this.closest('.product-detail');
  var pid = $element.find('div.s7-viewer').attr('data-productID');
  var currentSlide = 0;

  if ($this.hasClass('primary-image-img')) {
    currentSlide = $this.closest('.primary-image').data('index');
  } else {
    currentSlide = $this.parent().index();
  }

  $('.s7-viewer').empty();

  $('.s7-modal.' + pid)
    .modal('show')
    .on('shown.bs.modal', function () {
      $('.primary-thumbnails').addClass('modal-zoom-open').animate({ top: 0 }, 200);
      isPrimaryImageZoomActivated = true;
    })
    .on('hide.bs.modal', function () {
      $('.primary-thumbnails').removeClass('modal-zoom-open').removeAttr('style');
      isPrimaryImageZoomActivated = false;
    });

  activateZoomSlick($element, pid, currentSlide);
};

var imgActive = false;

var pdpImageActions = function () {
  var enterKeyPressed = function enterKeyPressed(e) {
    var code = e.charCode || e.keyCode;
    var ENTER_KEY = 13;
    return code === ENTER_KEY;
  };

  $('.product-detail').on('click keypress', '.primary-image img', function (e) {
    const $this = $(this);

    if (e.type === 'keypress') {
      if (enterKeyPressed(e)) {
        $this.trigger('click');
      }
      return;
    }
    activatePrimaryImageZoom($this);
  });

  $('.zoom-thumbnails').on('keypress', '.thumb-nail', function (e) {
    if (e.type === 'keypress') {
      if (enterKeyPressed(e)) {
        $(this).trigger('click');
      }
      return;
    }
  });

  $('.primary-thumbnails').on('click keypress', '.thumb-nail', function (e) {
    const $this = $(this);

    if (e.type === 'keypress') {
      if (enterKeyPressed(e)) {
        $this.trigger('click');
      }
      return;
    }

    if (isPrimaryImageZoomActivated) {
      setTimeout(() => {
        activatePrimaryImageZoom($this);
      }, 0);
    }

    imgActive = true;
    const $currentSlide = $('.primary-images').find('.slick-current');

    if ($currentSlide.offset()) {
      $html.add($body).animate(
        {
          scrollTop: $currentSlide.offset().top - 200
        },
        500
      );
    }
    var pid = $this.closest('.product-detail').attr('data-pid');
    if ($this.is('.video-player')) {
      baseBase.playVideoPlayer(pid);
    }
    setTimeout(function () {
      imgActive = false;
    }, 500);
    $body.trigger('adobeTagManager:altImageView');
  });

  $('.zoom-thumbnails').on('click keypress', '.thumb-nail', function (e) {
    $body.trigger('adobeTagManager:altImageView');
  });

  $(window).on('resize load', function () {
    const $primaryImage = $('.primary-images');

    $primaryImage.off('afterChange');
    if ($(window).width() < 1024) {
      $primaryImage.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $body.trigger('adobeTagManager:altImageView');
      });
    }
  });
};

base.updateAttribute = function () {
  $('body').on('product:afterAttributeSelect', function (e, response) {
    if ($('.product-detail>.bundle-items').length) {
      response.container.data('pid', response.data.product.id);
      response.container.find('.product-id').text(response.data.product.id);
      response.container.find('.bf-product-id').text(response.data.product.id);
    } else if ($('.product-set-detail').eq(0)) {
      response.container.data('pid', response.data.product.id);
      response.container.find('.product-id').text(response.data.product.id);
      response.container.find('.bf-product-id').empty().text(response.data.product.id);
    } else {
      $('.product-id').text(response.data.product.id);
      $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
      response.container.find('.bf-product-id').empty().text(response.data.product.id);
    }
    TurnToCmd('set', {
      sku: response.data.product.masterProductID || response.data.product.id
    }); //eslint-disable-line
  });
};

base.availability = baseBase.availability;

base.AfterWindowLoadStart = function () {
  const $sizeAttribute = $('.product-detail .attributes .size-attribute');

  if ($sizeAttribute.length > 0) {
    $sizeAttribute.css('pointer-events', 'all');
  }
};

base.pdpImageActions = pdpImageActions;

var sizeMenueOpen = false;
var sizeMenu =  $('.product-detail .show-size-dropdown-holder .custom-select');
function isOpen() {
  if(sizeMenueOpen)
    sizeMenu.addClass("size-menu-opened");
  else
    sizeMenu.removeClass("size-menu-opened");
}
sizeMenu.on("click", function() {
  sizeMenueOpen = !sizeMenueOpen;
  isOpen();
});

sizeMenu.on("blur", function() {
  if(sizeMenueOpen) {
    sizeMenueOpen = !sizeMenueOpen;
    isOpen();
  }
});

//overriding TurnTo clicks on sort and filters
base.turntoReviewButtonsObserver = function () {
  try {
    var prodReviewsButtonDiv = 'div#tt-reviews-list';
    var visitOnce = true;

    $(document).off("DOMNodeInserted", prodReviewsButtonDiv).on("DOMNodeInserted", prodReviewsButtonDiv , function (evt) {
      const $reviewsList = $(".product-details-page .pdp-reviews .tt-w-reviews-list");
      var pdpReviewButtons = $reviewsList.find(".tt-o-listbox__btn");

      if ((pdpReviewButtons.length === 2) && (visitOnce)) {

        var pdpReviewSortButton = $reviewsList.find(".tt-c-reviews-toolbar__sort .tt-o-listbox__btn");
        var pdpReviewFilterButton = $reviewsList.find(".tt-c-review-filters .tt-o-listbox__btn");
        var pdpReviewFilterList = $reviewsList.find(".tt-c-review-filters .tt-o-listbox__list");
        visitOnce = false;
        evt.stopPropagation();
        var sortMenueOpen = false;
        var filterMenueOpen = false;

        function showReviewMenu($element) {
          $element.attr('aria-expanded','true');
          $element.next().css("display", "block");
          $element.find(".tt-o-icon").addClass('tt-o-listbox__btn-icon--open');
        }

        function hideReviewMenu($element) {
          $element.attr('aria-expanded','false');
          $element.next().css("display", "none");
          $element.find(".tt-o-icon").removeClass('tt-o-listbox__btn-icon--open');
        }

        pdpReviewSortButton.on("click", function(event) {
            if (!sortMenueOpen) {
              showReviewMenu($(this));
              sortMenueOpen = true;
            } else {
              hideReviewMenu($(this));
              sortMenueOpen = false;
            }
          });

          $(document).on('click', function(event) {
            if (!$(event.target).closest(pdpReviewSortButton).length) {
              sortMenueOpen = false;
            }
          });

          pdpReviewFilterButton.on("click", function(e) {
            if (!filterMenueOpen) {
              showReviewMenu($(this));
              filterMenueOpen = true;
            } else {
              hideReviewMenu($(this));
              filterMenueOpen = false;
            }
          });

          $(document).on('click', function(e) {
            if ($(e.target).closest(pdpReviewFilterButton).length || $(e.target).closest(pdpReviewFilterList).length)
            return;
            else {
              filterMenueOpen = false;
            }
          });
      }
    });

  } catch(e) {
    console.log(e);
  }
};

base.addToCartActions = function () {
  var $addToCartActions = $('.prices-add-to-cart-actions');
  if ($addToCartActions.length) {
    var url = $addToCartActions.data('enableClickUrl');

    if (url) {
      $.ajax({
        url: url,
        type: 'get',
        success: function (data) {
          if (data.isEnabledForClick2OrderCSR) {
            $addToCartActions.find('.add-to-product-js').removeClass('d-none');
          } else {
            $addToCartActions.find('.add-to-product-content').removeClass('d-none');
          }
        }
      });
    }
  }
}

module.exports = base;
