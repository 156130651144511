'use strict';

let pdpVideoButton;

/**
 * Sets up the "Play Video" button functionlity.
 * @param {*} evt the click event
 */
function handleVideoButtonClick(evt) {
  const triggerEl = document.querySelector('.primary-images-wrapper .slick-dots li:last-child button') || document.querySelector('.thumb-nail.video-player');

  if (!triggerEl) {
    return;
  }
  triggerEl.dispatchEvent(new Event('click', { bubbles: true }));
  $(document).trigger('thumbnailCarousel:scrollTo', triggerEl.closest('li'));
  const pid = pdpVideoButton.closest('.product-detail').dataset.pid;
  window.base.playVideoPlayer(pid);
  evt.target.parentElement.classList.add('d-md-none');
}

/**
 * Sets up an event on the thumbnails to appropriately display the "play video" button
 * @param {*} evt The click event on the thumbnail wrapper
 */
function initThumbnailEvents(evt) {
  const thumbnails = document.querySelector('.primary-thumbnails');
  if (!thumbnails) {
    return;
  }
  thumbnails.addEventListener('click', evt => {
    const thumbnail = evt.target.closest('a[href^="#"]');
    if (!thumbnail) {
      return;
    }
    if (thumbnail.classList.contains('video-player')) {
      pdpVideoButton.parentElement.classList.add('d-md-none');
    } else {
      pdpVideoButton.parentElement.classList.remove('d-md-none');
    }
  });
}

/**
 * Initialize the Play Video button functionality.
 */
function init() {
  pdpVideoButton = document.querySelector('.pdp-video button');
  if (!pdpVideoButton) {
    return;
  }
  pdpVideoButton.addEventListener('click', handleVideoButtonClick);
  initThumbnailEvents();
}

module.exports = init;
