'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
  processInclude(require('./product/detail'));
  processInclude(require('./product/detailPrimaryImages'));
  window.detailThumbnailImageCarousel = require('./product/detailThumbnailImageCarousel');
  processInclude(window.detailThumbnailImageCarousel);
  processInclude(require('./product/detailProductVideo'));
  processInclude(require('./product/pdpInstoreInventory'));
  processInclude(require('./product/detailCompleteTheLook'));
  processInclude(window.productWishList);
  processInclude(window.productWishListHeart);
  processInclude(require('./product/updateAddToCart'))
  if (window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments()) {
    processInclude(require('./applePayPDPButton'));
  }
});

if ($('.lazy-load-content').length) {
  let scrollLimit = window.innerHeight / 3;
  let isLoaded = false;
  $(window).on('scroll', function() {
    let scrollPosition = $(this).scrollTop();
    let doLoad = false;

    if (!isLoaded) {
      const contents = $('.lazy-load-content');

      // Start loading when user scroll through 1/3 of the current screen, even if carousels are far below
      doLoad = scrollPosition >= scrollLimit;

      if (!doLoad) {
        const firstContainer = contents[0];
        const firstContainerBox = firstContainer.getBoundingClientRect();
        const isInViewport = firstContainerBox.top >= 0 &&
          firstContainerBox.left >= 0 &&
          firstContainerBox.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          firstContainerBox.right <= (window.innerWidth || document.documentElement.clientWidth);

        // Load if element already within viewport, or load when element is close enough to the viewport
        doLoad = isInViewport || (firstContainerBox.top - window.innerHeight < 150);
      }
    }

    if (!isLoaded && doLoad) {
      isLoaded = true;
      let contents = $('.lazy-load-content');
      let loadRecommSlotsURL = $('#loadRecommSlotsURL').val();
      let slotIds = [];
      for (let i = 0; i < contents.length; i++) {
        slotIds.push($(contents[i]).attr('data-slot-id'));
      }
      loadRecommSlotsURL = loadRecommSlotsURL + '&slotIds=' + slotIds.join(',');
      $.ajax({
        url: loadRecommSlotsURL,
        type: 'get',
        dataType: 'html',
        success: function (response) {
          for (let i = 0; i < contents.length; i++) {
            let recommSlot = contents[i];
            let slotId = $(recommSlot).attr('data-slot-id');
            $(recommSlot).html($(response).filter('.' + slotId).html());
          }
        }
      });
    }
  });
}
