'use strict';
var applePayButtonDiv = $('.pdp-apple-pay-button');

if (window.SitePreferences &&
    window.SitePreferences.applePayPDPToggle &&
    window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments() &&
    applePayButtonDiv.length) {
    const checkExist = setInterval(function () {
        const $applePayButton = $('.dw-apple-pay-button');
        if ($applePayButton.length) {
            clearInterval(checkExist);
            var wishlistBtn =  $('.wishlist-pdp-button');
            var resourceMsg = window.Resources;
            //If master is available during the page load, do the class manipulation for wishlist button
            if (applePayButtonDiv.length && applePayButtonDiv.data('available')) {
                if (wishlistBtn.length === 1) {
                    const wishlistLbl = wishlistBtn.find('.button-text');
                    if (wishlistLbl.length) {
                        wishlistLbl.text(resourceMsg.wishlistLbl);
                    }
                    const wishlistParentDiv = wishlistBtn.parent();
                    if (wishlistParentDiv.length && wishlistParentDiv.hasClass('col-12')){
                        wishlistParentDiv
                            .removeClass('col-12')
                            .addClass('col col-sm-6 applepay-wishlist applepay-wishlist');
                    }
                } else if (wishlistBtn.length > 1) {
                    wishlistBtn.each(function(i, wish) {
                        const jqCurrentWishBtn = $(wish);
                        const jqAddToCartBtn = $(jqCurrentWishBtn.parent().parent().siblings()[1]);
                        const wishBtnParentDiv = jqCurrentWishBtn.parent();

                        if (jqAddToCartBtn.length) {
                            if (jqAddToCartBtn.hasClass('add-to-cart') && !jqAddToCartBtn.hasClass('d-none')) {
                                if (wishBtnParentDiv.length && wishBtnParentDiv.hasClass('col-12')){
                                    wishBtnParentDiv
                                        .removeClass('col-12')
                                        .addClass('col col-sm-6 applepay-wishlist applepay-wishlist');
                                }
                            }
                        }
                    });
                } else {
                    applePayButtonDiv.removeClass('col-sm-6');
                    applePayButtonDiv.removeClass('pdp-apple-pay-button');
                    applePayButtonDiv.addClass('col-12');
                }
            }
            $applePayButton.on('click', function () {
                const $body = $('body');
                $body.trigger('adobeTagManager:exitToPaymentService', 'applepay');
                var $productDetail = $(this).closest('.product-detail');
                var $selectSizeColor = $productDetail.find('.select-size-color');
                $selectSizeColor.empty();
                const $errorMessage = $('#applePayErrorMsgDiv');
                if ($errorMessage.length) {
                    $errorMessage.addClass('d-none');
                }
                if (!$(this).parent().data('readytoorder')) {
                    let formData = {};
                    formData.errorFields = ['no variant selected'];
                    formData.formName = 'Add to Cart';
                    $body.trigger('adobeTagManager:formError', formData);
                    const sDiv = $('<div class="col-12 select-size-div" tabindex="0">');
                    sDiv.html($(this).parent().data('readytoordertext'));
                    $selectSizeColor.html(sDiv);
                }
            });
        }
    }, 100);
    if ($('.add-to-cart-messages').length) {
        const checkQuantityErrorMessage = setTimeout(function () {
            clearInterval(checkQuantityErrorMessage);
            $('.add-to-cart-messages .error').remove();
        }, 3000);
    }
} else {
    if (applePayButtonDiv.length) {
        applePayButtonDiv.addClass('d-none');
    }
}
