'use strict';

/**
 * Finds the associated full size image referenced by the anchor link on a thumbnail image.
 * @param {element} thumbnailLink The thumbnail link element
 */
const getAssociatedImage = function (thumbnailLink) {
  return document.querySelector(thumbnailLink.href.replace(/(.*)#(.*)/, '#$2'));
};

/**
 * Sets a the primary image as active, deactivating other active images in the process.
 * @param {Element} el The primary image to make active
 */
const updateActiveImage = function (el) {
  const classActive = 'active';
  const classPreviouslyActive = 'previously-active';
  [].forEach.call(el.parentNode.querySelectorAll('.' + classActive), image => {
    image.classList.add(classPreviouslyActive);
    image.classList.remove(classActive);
  });
  const removePreviouslyActiveClass = () => {
    [].forEach.call(el.parentNode.querySelectorAll('.' + classPreviouslyActive), image => image.classList.remove(classPreviouslyActive));
    el.removeEventListener('animationend', removePreviouslyActiveClass);
  };
  el.addEventListener('animationend', removePreviouslyActiveClass);
  el.classList.add(classActive);
};

/**
 * Sets a thumbnail as active, deactivating other thumbnails in the process.
 * @param {Element} el The thumbnail link to make active
 */
const updateActiveThumbnail = function (el) {
  var active = document.querySelectorAll('.primary-thumbnails a.active');
  [].forEach.call(active, function (link) {
    link.classList.remove('active');
  });
  if (el) {
    el.classList.add('active');
  }
};

/**
 * Sets up scrolling to a target image when a user clicks on a thumbnail. Bound to the click event on the thumbnails.
 * @param {Event} evt The event object.
 */
const handleThumbnailClick = function (evt) {
  let thumbnailLink = evt.target;
  if (!thumbnailLink.matches('a[href^="#"]')) {
    thumbnailLink = evt.target.closest('a');
  }
  if (!thumbnailLink) {
    return;
  }
  evt.preventDefault();
  const targetImage = getAssociatedImage(thumbnailLink);
  if (!targetImage) {
    return;
  }

  updateActiveImage(targetImage);
  updateActiveThumbnail(thumbnailLink);
};

/**
 * Initializes thumbnail image links
 */
const thumbnailImageLinks = function () {
  const thumbnailLinks = document.querySelector('.primary-thumbnails');
  if (!thumbnailLinks) {
    return;
  }
  thumbnailLinks.addEventListener('click', handleThumbnailClick);
};

const initPrimaryImages = function () {
  // Set up thumbnails.
  thumbnailImageLinks();
};

module.exports = initPrimaryImages;
