const htmlFragments = require('../util/htmlFragments');

let ctlWrapper;
let drawer;
let backdrop;
let posinset = 1;

/**
 * Removes falsy values from an array
 * @param {Array} arr The array you want to remove falsy values from
 */
const compact = arr => arr.filter(Boolean);

/**
 * Strips extra whitespace from an element's child nodes. Allows `:empty` selector to work as intended
 * @param {Element} parent The element to strip extra whitespace
 */
const stripWhiteSpaceNodes = parent => {
  const nodes = parent.childNodes;
  [].forEach.call(nodes, node => {
    if (node.nodeType === 3 && !/\S/.test(node.nodeValue)) {
      parent.replaceChild(document.createTextNode(''), node);
    } else if (node.nodeType === 1) {
      stripWhiteSpaceNodes(node);
    }
  });
};

/**
 * Removes an element from the DOM. Does nothing if the element doesn't exist in the DOM
 * @param {Element} element A DOM element to remove from the page.
 */
const remove = element => {
  if (element) {
    element.parentElement.removeChild(element);
  }
};

/**
 * Sets up the CTL Drawer HTML
 * @param {String} bodyContent HTML string representing content to render in the drawer body
 * @param {String} footerContent HTML string representing content to render in the drawer footer
 */
const buildDrawer = (bodyContent = '', footerContent = '') => {
  if (drawer) {
    drawer.parentElement.removeChild(drawer);
  }

  const template = `
		<div class="modal complete-the-look" id="completeTheLookDrawer" tabindex="-1" role="dialog" data-pid="" data-master-pid="">
			<section class="modal-content product-detail">
				<header class="modal-header">
					<button type="button" class="close" data-dismiss="modal">
						<span aria-hidden="true" class="cancel-icon svg-36-avenue-Up_Copy svg-36-avenue-Up_Copy-dims"></span>
					</button>
					<span class="sr-only"></span>
				</header>
				<div class="modal-body">${bodyContent}</div>
				<div class="modal-footer">${footerContent}</div>
			</section>
		</div>`;

  document.body.insertAdjacentHTML('beforeend', template);
  drawer = document.querySelector('#completeTheLookDrawer');
};

/**
 * Builds the backdrop behind the drawer
 */
const buildBackdrop = () => {
  const template = '<div class="modal-backdrop fade"></div>';
  document.body.insertAdjacentHTML('beforeend', template);
  backdrop = document.querySelector('body > .modal-backdrop');
  backdrop.addEventListener('click', () => closeDrawer());
};

/**
 * Opens the CTL Modal Drawer
 */
const openDrawer = () => {
  document.body.classList.add('modal-open');
  buildDrawer();
  buildBackdrop();
  backdrop.classList.add('show');
  drawer.style.display = 'block';
  window.setTimeout(() => {
    drawer.classList.add('show');
  }, 10);
};

/**
 * Closes the CTL Modal drawer
 */
const closeDrawer = () => {
  if (!drawer) {
    return;
  }
  document.body.classList.remove('modal-open');
  drawer.classList.remove('show');
  backdrop.classList.remove('show');

  backdrop.addEventListener('transitionend', () => {
    drawer.parentElement.removeChild(drawer);
    backdrop.parentElement.removeChild(backdrop);
    drawer = null;
    backdrop = null;
  });
};

/**
 * Gets the constituent blocks necessary to render the layout of the CTL drawer
 * @param {Object} template A document fragment object to parse
 * @param {String} section What section we're getting the blocks for, 'body' or 'footer'. Defaults to 'body'
 * @returns an array of elements, in the order in which they'll get rendered. Empty elements are filtered out.
 */
const getFragmentBlocks = (template, section = 'body') => {
  const blocks = [];
  if (section === 'body') {
    // Remove the actions from the attributes block
    const attributes = template.querySelector('.attributes');
    remove(attributes.querySelector('.js-add-to-cart'));

    const primaryImages = template.querySelector('.primary-images-container');
    const ctlImage = template.querySelector('.primary-images');
    if (primaryImages) {
      // Get rid of bootstrap classes on the primary image container
      primaryImages.setAttribute('class', 'primary-images-container');
      // Add Class for HBC Slider for Modal Only
      ctlImage.classList.add('ctl-images');
      // Also remove the thumbnails if they're there
      remove(primaryImages.querySelector('.primary-thumbnails'));
      remove(primaryImages.querySelector('.pdp-video'));
    }

    const productAvailability = attributes.querySelector('.product-availability');
    if (productAvailability) {
      stripWhiteSpaceNodes(productAvailability);
    }

    blocks.push(template.querySelector('.brand-segment'));
    blocks.push(template.querySelector('.product-segment'));
    blocks.push(primaryImages);
    blocks.push(template.querySelector('.price-segment'));
    blocks.push(attributes);
  } else if (section === 'footer') {
    const addToCart = template.querySelector('.js-add-to-cart');
    const preorderShipDate = addToCart.querySelector('.preorder-ship-date');
    if (preorderShipDate) {
      stripWhiteSpaceNodes(preorderShipDate);
    }
    blocks.push(addToCart);
  }
  return compact(blocks);
};

/**
 * Builds an HTML string out of an array of elements
 * @param {Array} blocks an array of elements
 * @returns An HTML string built from the array of elements
 */
buildDrawerHTML = blocks => {
  return blocks.reduce((result, block) => result + block.outerHTML, '');
};

getRecommenderCode = function () {
  let code = '';

  if (window.pageData && window.pageData.products && (window.pageData.products.length !== undefined)) {
      code = window.pageData.products[0].code;
  }

  return code;
}

/**
 * Handles the response when requesting product details via ajax
 * @param {Object} response The XHR Response for product details
 */
const processResponse = response => {
  const template = htmlFragments.createFragment(response.renderedTemplate.replace(/\&amp;/gi, '&'));
  const footerBlocks = getFragmentBlocks(template, 'footer');
  const bodyBlocks = getFragmentBlocks(template);

  drawer.querySelector('.modal-body').innerHTML = buildDrawerHTML(bodyBlocks);
  drawer.querySelector('.modal-footer').innerHTML = buildDrawerHTML(footerBlocks);
  drawer.setAttribute('data-pid', response.product.id);
  drawer.setAttribute('data-master-pid', response.product.masterProductID);
  $('.js-mobile-opt-msg').empty().html($('.js-waitlist-opt-message').html());

  try {
    window.hbcSlider.hbcSliderInit('primary-images');
    $('body').trigger('adobeTagManager:shopTheLook', {
      posinset: posinset,
      recommender: getRecommenderCode(),
      product: response.product
    });
  } catch (e) {}
};

/**
 * Handle tile clicks
 * @param {Object} evt The event object
 */
const handleTileClick = evt => {
  evt.preventDefault();
  const tile = evt.target.closest('.product-tile');
  const clickedWishlistLink = !!evt.target.closest('[class*="select-wishlist"]'); // The attribute selector covers both .select-wishlist AND .deselect-wishlist
  const clickedInWishlistContainer = !!evt.target.closest('.wishlist_container');
  if (!tile || clickedWishlistLink) {
    return;
  }

  if(clickedInWishlistContainer) {
    const url = $(evt.target).attr('href');
    window.location.href = url;
    return;
  }

  const quickviewLink = tile.querySelector('[data-quickview-url]');
  if (!quickviewLink) {
    return;
  }

  const $tileWrapper = tile.closest('.tile-wrapper');
  if ($tileWrapper) {
    posinset = $tileWrapper.getAttribute('data-posinset');
  }

  openDrawer();

  $.ajax({
    url: quickviewLink.dataset.quickviewUrl,
    method: 'GET',
    dataType: 'json'
  }).then(processResponse);
};

/**
 * Handles attribute (color, size, etc), actions within the drawer
 */
const setAttributeUpdateListeners = function () {
  $('body').on('product:afterAttributeSelect', (e, response) => {
    if (!drawer) {
      return;
    }

    [].forEach.call(drawer.querySelectorAll('[data-pid]'), el => (el.dataset.pid = response.data.product.id));
    drawer.querySelector('.view-full-product-info').href = response.data.product.selectedProductUrl;
    drawer.setAttribute('data-pid', response.data.product.id);
  });
};

/**
 * Handles add to cart messaging when updating attributes.
 */
const setAddToCartListeners = () => {
  $('body').on('product:updateAddToCart', (e, response) => {
    if (!drawer) {
      return;
    }
    const addToCartButton = drawer.querySelector('.add-to-cart-global');
    const preorderShipDate = drawer.querySelector('.preorder-ship-date');

    if (addToCartButton) {
      addToCartButton.disabled = !response.product.readyToOrder || !response.product.available;
      addToCartButton.dataset.readytoorder = response.product.readyToOrder && response.product.available;
      addToCartButton.dataset.readytoordertext = response.product.readyToOrderMsg;
      $(addToCartButton).data('readytoorder', addToCartButton.dataset.readytoorder);
    }

    if (response.product.preOrder && response.product.preOrder.applicable && response.product.preOrder.applicable === true) {
      addToCartButton.innerText = response.product.preOrder.preorderButtonName;
      if (response.product.preOrder.shipDate && preorderShipDate) {
        preorderShipDate.innerText = response.product.preOrder.shipDate;
      }
    } else if (addToCartButton && preorderShipDate) {
      addToCartButton.innerText = response.product.availability.buttonName;
      preorderShipDate.innerHTML = '';
    }
  });
};

/**
 * Handles availablity updates
 */
const setUpdateAvailabilityListeners = () => {
  $('body').on('product:updateAvailability', (e, response) => {
    if (!drawer) {
      return;
    }
    const productAvailability = drawer.querySelector('.product-availability');

    if (productAvailability) {
      productAvailability.dataset.readyToOrder = response.product.readyToOrder;
      productAvailability.dataset.available = response.product.available;
      productAvailability.querySelector('.availability-msg').innerHTML = response.message;
    }
  });
};

/**
 * Remove `target` attribute on all thumbs
 */
const initThumbs = () => {
  [].forEach.call(ctlWrapper.querySelectorAll('.thumb-link'), thumb => {
    thumb.removeAttribute('target');
    if (!window.FABuild) {
      thumb.addEventListener('click', function (e) {
        e.preventDefault();
      });
    }
  });
};

/**
 * Set up click handling on tiles
 */
const initTiles = () => {
  [].forEach.call(ctlWrapper.querySelectorAll('.product-tile'), tile => {
    tile.addEventListener('click', handleTileClick);
    if (!window.FABuild) {
      [].forEach.call(tile.querySelectorAll('.tile-body a'), anchor => {
        anchor.addEventListener('click', e => {
          e.preventDefault();
        });
      });
    }
  });
};

/**
 * Sets up events and listners specific to interactions within the CTLdrawer.
 */
const initEvents = () => {
  document.body.addEventListener('click', evt => {
    if (evt.target.closest('.modal .close')) {
      closeDrawer();
    }
  });
  setAttributeUpdateListeners();
  setAddToCartListeners();
  setUpdateAvailabilityListeners();
  $('body').on('product:afterAddToCart', closeDrawer);
};

/**
 * Set up Complete The Look functionality
 */
const init = () => {
  ctlWrapper = document.querySelector('.complete-the-look-sec');
  if (!ctlWrapper) {
    return;
  }
  initThumbs();
  initTiles();
  initEvents();
};

module.exports = init;
