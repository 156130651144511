'use strict';

let thumbnailImageContainer;
let containerPaddingTop;
let lastThumb;
let thumbMarginTop;
let thumbMarginBottom;
let totalThumbMargin;
let upButton;
let downButton;

const MAX_VISIBLE_THUMNAILS = 5;

/**
 * Sets the scroll buttons as enabled or disabled, depending where the scroll container is scrolled to.
 */
function updateButtonStatus() {
  if (thumbnailImageContainer.scrollTop <= 0) {
    // We're at the top
    upButton.disabled = true;
  } else if (thumbnailImageContainer.offsetHeight + thumbnailImageContainer.scrollTop >= thumbnailImageContainer.scrollHeight - totalThumbMargin) {
    // We're at the bottom
    downButton.disabled = true;
  } else {
    upButton.disabled = false;
    downButton.disabled = false;
  }
}

/**
 * Gets the index of an element in the thumbnail carousel
 * @param {Element|String} targetSlide If string, treated as a selector, otherwise an element reference
 * @returns {Number} The index of the referenced slide. If not found, returns -1
 */
function getIndexOfSlide(targetSlide) {
  let slide = targetSlide;
  if (typeof targetSlide === 'string') {
    slide = thumbnailImageContainer.querySelector(targetSlide);
  }
  return [].indexOf.call(thumbnailImageContainer.children, slide);
}

/**
 * Handles scrolling calculations
 *
 * @param {Number|Element|String} targetSlide Depending on the argument type:
 * 	Number: the index of the target slide
 *  String: selector for the target slide
 *  Element: the target slide
 */
function scrollTo(targetSlide) {
  let targetSlideIndex = typeof targetSlide === 'number' ? targetSlide : getIndexOfSlide(targetSlide);

  if (targetSlideIndex < 0) {
    return;
  }

  thumbnailImageContainer.scrollTop =
    thumbnailImageContainer.children[targetSlideIndex].offsetTop - (containerPaddingTop + Math.max(thumbMarginTop, thumbMarginBottom));
}

/**
 * Scroll by a set number of slides
 * @param {Number} steps The number of steps to scroll by. Clamped to the total number of slides.
 */
function scrollBy(steps) {
  // We're assuming all thumbnails are the same height with these calculations
  const thumbnailHeight = lastThumb.offsetHeight + thumbMarginBottom;
  const topVisibleSlide = Math.floor(thumbnailImageContainer.scrollTop / thumbnailHeight);
  const targetSlide = Math.min(Math.max(0, topVisibleSlide + steps), thumbnailImageContainer.children.length - 1);
  scrollTo(targetSlide);
}

function scrollUp() {
  scrollBy(MAX_VISIBLE_THUMNAILS * -1);
}

function scrollDown() {
  scrollBy(MAX_VISIBLE_THUMNAILS);
}

function createNavigationButtons() {
  upButton = document.createElement('button');
  upButton.setAttribute('class', 'thumbnail-nav up');
  upButton.setAttribute('aria-label', 'Scroll Up');
  upButton.addEventListener('click', scrollUp);
  thumbnailImageContainer.insertAdjacentElement('afterend', upButton);

  downButton = document.createElement('button');
  downButton.setAttribute('class', 'thumbnail-nav down');
  downButton.setAttribute('aria-label', 'Scroll Down');
  downButton.addEventListener('click', scrollDown);
  thumbnailImageContainer.insertAdjacentElement('afterend', downButton);

  thumbnailImageContainer.addEventListener('scroll', updateButtonStatus);
}

function initScrollableFunctionality() {
  if (thumbnailImageContainer.childElementCount <= MAX_VISIBLE_THUMNAILS) {
    if (thumbnailImageContainer.classList.contains('scrollable')) {
      thumbnailImageContainer.classList.remove('scrollable');
      const nextDownBtn = $('.primary-thumbnails').next('.thumbnail-nav.down');
      if(nextDownBtn.length > 0) {
        const nextUpBtn = nextDownBtn.next('.thumbnail-nav.up');
        nextDownBtn.remove();
        if(nextUpBtn.length > 0) {
          nextUpBtn.remove();
        }
      }
    }
    return;
  }

  const containerStyle = getComputedStyle(thumbnailImageContainer);
  containerPaddingTop = parseInt(containerStyle['padding-top'], 10);
  lastThumb = thumbnailImageContainer.children[thumbnailImageContainer.children.length - 1];
  const thumbStyle = getComputedStyle(lastThumb);
  thumbMarginTop = parseInt(thumbStyle['margin-top'], 10);
  thumbMarginBottom = parseInt(thumbStyle['margin-bottom'], 10);

  totalThumbMargin = thumbnailImageContainer.children.length * Math.max(thumbMarginTop, thumbMarginBottom) + thumbMarginBottom;

  createNavigationButtons();
  updateButtonStatus();
}

function initThumbnailImageCarousel() {
  thumbnailImageContainer = document.querySelector('.primary-thumbnails');
  // SFSX-2292 - We don't want to initialize this on the item set template.
  if (!thumbnailImageContainer || (thumbnailImageContainer && thumbnailImageContainer.closest('.set-items'))) {
    return;
  }
  initScrollableFunctionality();
  $(document).on('thumbnailCarousel:scrollTo', (evt, slide) => scrollTo(slide));
}

module.exports = initThumbnailImageCarousel;
